import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            minWidth: 120,
            width: "100%",
            borderColor: "#f66",
            borderWidth: 2
        },
        input: {
            borderColor: "#f66"
        }

    }),
);