import { combineReducers } from 'redux';
import { StoreState } from '../createStore';

import auth from './auth/reducer'
import user from './user/reducer'

export default combineReducers<StoreState>({
    _persist: (state: any = null) => state,
    auth,
    user
})


