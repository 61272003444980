export const student = {
    'students/1': {
        _id: 1,
        name: "Ezequiel Lopes dos Reis Junior",
        email: "ezequiel@outlook.com",
        phone: "(38)90000-0000",
        cellphone: "(38)9900-0000",
        last_interaction: "2021-10-27T14:12:14.520Z",
        categories: [
            {
                _id: 163,
                name: "Full Cycle",
                classroom_id: 1,
                classroom_name: "Turma 1"
            }
        ]
    },
    'students/2': {
        _id: 636,
        name: "Euller Cristian",
        email: "euller@gmail.com",
        phone: "(38)99000-9999",
        cellphone: "(37)9900-1111",
        last_interaction: "",
        categories: [
            {
                _id: 163,
                name: "Full Cycle",
                classroom_id: 1,
                classroom_name: "Turma 2"
            }
        ]
    },
    'students': {
        "data": [
            {
                _id: 636,
                category_id: 163,
                name: "Euller Cristian",
                email: "eullercdr@gmail.com",
                phone: "37 91766255",
                cellphone: " ",
                classroom_id: 167,
                classroom_name: "Turma 2",
                last_interaction: "2021-08-20T20:17:26.000Z"
            },
            {
                _id: 1,
                category_id: 163,
                name: "Ezequiel Lopes dos Reis Junior",
                email: "ezequiel.jn98@outlook.com",
                phone: "(38)99000-9999",
                cellphone: "(37)9900-4520",
                classroom_id: 1,
                classroom_name: "Turma 1",
                last_interaction: "2021-10-27T14:12:14.520Z"
            }
        ],
        "meta": {
            total: 2,
            perPage: 30,
            lastPage: 1,
            nextPage: 1,
            prevPage: 1,
            currentPage: 1
        }
    },
    'students/1001/stats-course': [
        {
            _id: 103,
            last_interaction: "2021-09-17T16:37:51.540Z",
            contents_finished: 1,
            contents_started: 0
        }
    ],
    "students/1/category/163/stats-category": [
        {
            _id: 1,
            categories: {
                _id: 163,
                total_contents: 1027
            },
            students: {
                contents_finished: 267,
                last_interaction: "2021-10-27T14:12:14.520Z"
            }
        }
    ],
    "students/2/category/163/stats-category": [
        {
            _id: 2,
            categories: {
                _id: 163,
                total_contents: 1000
            },
            students: {
                contents_finished: 1100,
                last_interaction: "2021-10-27T14:12:14.520Z"
            }
        }
    ],
    "students/3/category/163/stats-category": [
        {
            _id: 2,
            categories: {
                _id: 163,
                total_contents: 10
            },
            students: {
                contents_finished: 100,
                last_interaction: "2021-10-27T14:12:14.520Z"
            }
        }
    ],
    "students/4/category/163/stats-category": [
        {
            _id: 2,
            categories: {
                _id: 163,
                total_contents: 1000
            },
            students: {
                contents_finished: 200,
                last_interaction: ""
            }
        }
    ],
    "students/1/category/163/course/102/chapter/676/contents": [
        {
            _id: 5917,
            started: "2021-06-18T18:35:23.000Z",
            finished: null,
            views: 0
        },
        {
            _id: 5918,
            started: "2021-06-18T18:35:23.000Z",
            finished: "2021-06-18T18:35:23.000Z",
            views: 1
        }
    ],
    "students/1/category/163/stats-course": [
        {
            _id: 1,
            categories: {
                _id: 163,
                total_contents: 1027
            },
            students: {
                contents_finished: 2,
                last_interaction: "2021-10-27T14:12:14.520Z"
            }
        }
    ]
}