import produce from "immer";
import { removeCookies, setCookies } from "../../../function/cookies";

import { AuthAction, AuthState } from "./types";

const initialState: AuthState = {
    loading: false,
};

export default function auth(
    state = initialState,
    action: AuthAction
): AuthState {
    return produce(state, (draft) => {
        switch (action.type) {
            case "@auth/SIGN_IN_SUCCESS":
                setCookies(action.payload.token);
                break;
            case "@auth/SIGN_OUT":
                removeCookies();
                break;
            case "@auth/LOADING":
                draft.loading = action.payload.state;
                break;
            default:
                return state;
        }
    });
}
