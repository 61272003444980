import storage from "redux-persist/lib/storage";
import { Reducer } from "redux";
import { persistReducer } from "redux-persist";
import { StoreAction, StoreState } from "./createStore";

export default (reducers: Reducer<StoreState, StoreAction>) => {
    const persistedReducer = persistReducer(
        {
            key: "codestudent",
            storage,
            whitelist: ["user"],
            blacklist: ["auth"],
        },
        reducers
    );
    return persistedReducer;
};
