import { takeLatest, call, put, all } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import * as authActions from "./actions";
import * as userActions from "../user/actions";
import { api } from "../../../services/api";
import { UserLoginHttp } from "../../../services/api/resource";
import jwt_decode from "jwt-decode";

interface signInResponseApi {
    token: string;
}

interface userProps {
    name: string;
    email: string;
}

async function Login({ email, password }: { email: string; password: string }) {
    const userLogin = await new UserLoginHttp(api, { email, password }).login();
    return userLogin;
}

export function* signIn({
    payload,
}: ActionType<typeof authActions.signInRequest>) {
    const { email, password, setErrorMessage } = payload;
    try {
        yield put(authActions.loadingAuth(true));
        const response: signInResponseApi = yield call(Login, {
            email,
            password,
        });
        yield put(authActions.signInSuccess(response.token));
        const user: userProps = jwt_decode(response.token);
        yield put(
            userActions.signInSuccessUser({
                email: user.email,
                name: user.name,
            })
        );
        yield put(authActions.loadingAuth(false));
    } catch (err: any) {
        if (err.response?.status === 401) {
            setErrorMessage("Email ou senha inválidos.");
        } else {
            setErrorMessage("Algo inesperado aconteceu, tente novamente.");
        }
        yield put(authActions.loadingAuth(false));
    }
}

export default all([takeLatest("@auth/SIGN_IN_REQUEST", signIn)]);
