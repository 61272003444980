import { AxiosInstance, AxiosResponse } from "axios";
import { HttpResource } from "../../httpResource";
import {
    CategoryInfoProps,
    CategoryProps,
    CategoryStaticStudentProps,
    ClassroomsProps
} from "./types";


export class CategoryCourseHttp extends HttpResource {
    categoryId: number | string;

    constructor(public http: AxiosInstance, categoryId: any) {
        super(http, `categories/${categoryId}/stats-courses`);
        this.categoryId = categoryId;
    }

    async info({ studentId, categoryId }: CategoryInfoProps) {
        if (!studentId || !categoryId) {
            return {
                "studentId": 0,
                "categoryId": 0,
                "total": 0,
                "finished": 0,
                "porcent": 0,
                "last_interaction": ""
            }
        }
        const [categoryData] = await super.get<CategoryStaticStudentProps[]>(`students/${studentId}/category/${categoryId}/stats-category`);

        if (!categoryData) {
            return {
                "studentId": studentId,
                "categoryId": categoryId,
                "total": 0,
                "finished": 0,
                "porcent": 0,
                "last_interaction": ""
            }
        }
        const calculateThePercentage = Number(categoryData.categories.total_contents ?
            ((categoryData.students.contents_finished * 100)
                / categoryData.categories.total_contents).toFixed(2)
            : 0)

        const percentageGreater100OrLess0 =
            calculateThePercentage > 100 ? 100.00 :
                calculateThePercentage < 0 ? 0.00 :
                    calculateThePercentage

        return {
            "studentId": categoryData._id,
            "categoryId": categoryData.categories._id,
            "total": categoryData.categories.total_contents,
            "finished": categoryData.students.contents_finished,
            "porcent": percentageGreater100OrLess0,
            "last_interaction": categoryData.students.last_interaction
        }
    }
}




export class CategoryOptionHttp extends HttpResource {
    constructor(public http: AxiosInstance) {
        super(http, 'categories/options');
    }
}

export class CategoriesClassroomHttp extends HttpResource {
    courseId: number | string;
    constructor(public http: AxiosInstance, courseId: number | string) {
        super(http, `/categories/${courseId}`);
        this.courseId = courseId;
    }
    async classroom(categoryId: number | string): Promise<ClassroomsProps[]> {
        if (!categoryId) {
            return []
        }
        
        const categories = await this.get<CategoryProps>(`/categories/${categoryId}`)
        
        if (categories?.classrooms) {
            return categories.classrooms
        }
        return []

    }
}

