import { memo } from 'react'
import { Text, Box, Avatar, Flex } from '@chakra-ui/react'
import { Grid } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { format } from 'date-fns'
import { useStyles } from './styles'
import BookReadWhite from '../../assets/logo/book-read-user-white.svg'
import { useHistory } from "react-router-dom"

import { BsSearch } from 'react-icons/bs'



interface GridStudentsProps {
    _id: number;
    name: string;
    email: string;
    phone: string;
    cellphone: string;
    last_interaction: string | null;
    matchesDownMd: boolean;
    matchesDownSm: boolean;
    active?: boolean;
    categories: string;
    backRouter: "email" | "students";

}

function Students({ _id, active, backRouter, matchesDownMd, matchesDownSm, name, email, phone, cellphone, categories, last_interaction }: GridStudentsProps) {
    const classes = useStyles()
    const history = useHistory()

    function Home() {
        return history.push(`/student/${_id}/${backRouter}`)
    }


    return (
        <Grid container spacing={2} className={classes.gridContainer} >
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4} >
                <Box display="flex" flexDirection="row" >
                    <Box>
                        <Tooltip title={_id} aria-label="pdf">
                            <Flex w="50px" h="50px" bg={active ? "green.500" : "red.300"} align="center" justify="center" borderRadius="25px">
                                <Avatar boxSize="30px" name={name} bg="rgba(0,0,0,0)" color="white" width="20px" src={active ? BookReadWhite : BookReadWhite} />
                            </Flex>
                        </Tooltip>
                    </Box>
                    <Box ml="4" >
                        <Text fontSize="16px" fontWeight="bold" color={active ? "font" : "fontDisabled"}>{name}</Text>
                        <Text color={active ? "font" : "fontDisabled"}>{email}</Text>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2} xl={2} >
                <Box ml={matchesDownSm ? "70px" : "0px"} display="flex" justifyContent={matchesDownMd ? "flex-start" : "center"} >
                    <Text fontWeight="bold" color={active ? "font" : "fontDisabled"}>Telefone</Text>
                </Box>
                <Box ml={matchesDownSm ? "70px" : "0px"} display="flex" flexDirection="column" align={matchesDownMd ? "flex-start" : "center"}>
                    <Box minWidth="50%">
                        <Text color={active ? "font" : "fontDisabled"}>{phone}</Text>
                    </Box>
                    <Box >
                        <Text color={active ? "font" : "fontDisabled"}>{cellphone}</Text>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2} xl={2} >
                <Box display="flex" flexDirection="column" ml={matchesDownMd ? "70px" : "0px"} alignItems={matchesDownMd ? "flex-start" : "center"}>
                    <Text fontWeight="bold" color={active ? "font" : "fontDisabled"}>Último Acesso</Text>
                    <Text color={active ? last_interaction ? "black" : "red.500" : "fontDisabled"} >{last_interaction ? format(new Date(last_interaction), "dd/MM/yy HH:mm") : "Nunca Acessou"}</Text>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={3} xl={3}>
                <Box ml={matchesDownSm ? "70px" : "0px"} display="flex" flexDirection="column" alignItems={matchesDownMd ? "flex-start" : "center"}>
                    <Text fontWeight="bold" color={active ? "font" : "fontDisabled"}>Turma</Text>
                    <Text color={active ? "font" : "fontDisabled"}>{categories}</Text>
                </Box>
            </Grid>

            <Grid item xs={12} sm={1} md={1} lg={1} xl={1}  >
                <Flex flexDirection="column" h="100%" justifyContent="center" alignItems="center" cursor="pointer" onClick={Home}>
                    <BsSearch size={26} />
                </Flex>
            </Grid>


            <Grid item xs={12}>
                <Box h="1px" w="100%" bg="border" />
            </Grid>
        </Grid>
    )

}
const GridStudents = memo(Students)
export { GridStudents }