import produce from "immer";

import { UserAction, UserState } from "./types";

const initialState: UserState = {
    name: "",
    email: "",
    isSigned: false,
};

export default function user(
    state = initialState,
    action: UserAction
): UserState {
    return produce(state, (draft) => {
        switch (action.type) {
            case "@user/SIGN_IN_SUCCESS":
                draft.name = action.payload.name;
                draft.email = action.payload.email;
                draft.isSigned = true;
                break;
            case "@auth/SIGN_OUT":
                draft.name = "";
                draft.email = "";
                draft.isSigned = false;
                break;
            default:
                return state;
        }
    });
}
