import React, { ElementType } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Image, Box } from '@chakra-ui/react'
import { useTheme } from '@material-ui/core'

interface LisItemIconProps {
    icon?: ElementType | any;
    title: string;
    isImage?: boolean;
    onClick: () => void;
    active: boolean;
}

const ItemIcon: React.FC<LisItemIconProps> = ({ active, title, onClick, icon: Icon, isImage = false }) => {

    const theme = useTheme();

    return (
        <ListItem onClick={onClick}
            button
            key={title}
            style={{ background: active ? theme.palette.primary.main : "" }}>
            <ListItemIcon>
                {
                    isImage ?
                        <Image src={Icon} /> :
                        <Box as={Icon} size="22px" color="black" />
                }
            </ListItemIcon>
            <ListItemText primary={title} />
        </ListItem>
    )
}

export { ItemIcon };