import { extendTheme } from "@chakra-ui/react"
import { createTheme } from '@material-ui/core'

const theme = extendTheme({
    colors: {
        black: "#303030",
        border: "#E2E2E2",
        primary: "#FCCA50",
        white: "#fff",
        font: "#333333",
        fontDisabled: "#D8D8D8",
        subText: "#999999",
        backgroundCard: "#f6f6f6"
    },
    styles: {
        global: {
            body: {
                bg: '#fafafa'
            }
        }
    }
})



const themeMaterial = createTheme({
    palette: {
        common: {
            black: "#333333",
            white: "#fff",
        },
        primary: {
            main: "#fcca50",
            dark: "#d69703"
        },
        secondary: {
            main: "#0747F7",
            dark: "#002DAB"
        },
        background: {
            paper: "#fafafa",
            default: "#fafafa"
        },
        text: {
            primary: "#000",
            secondary: "#adadad",
            disabled: "#fff",
            hint: "rgba(0, 0, 0, 0.38)",
        },

        divider: "#999999",
        action: {
            active: "#fcca50",
            hover: "#adadad",
            hoverOpacity: 0.04,
            selected: "rgba(0, 0, 0, 0.08)",
            selectedOpacity: 0.08,
            disabled: "rgba(0, 0, 0, 0.26)",
            disabledBackground: "rgba(0, 0, 0, 0.12)",
            disabledOpacity: 0.38,
            focus: "rgba(0, 0, 0, 0.12)",
            focusOpacity: 0.12,
            activatedOpacity: 0.12
        }

    }
});

export { theme, themeMaterial }
