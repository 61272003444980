import { useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip';
import { SiMicrosoftexcel } from 'react-icons/si'
import IconButton from '@material-ui/core/IconButton';
import { api } from '../../services/api';
import XLSX from 'xlsx'
import ClipLoader from "react-spinners/ClipLoader";
import { format } from 'date-fns'


interface MakeExcelStudentsProps {
    queryurlExcel: string;
    isCourseSelected: boolean;
}



interface RowsFormatterProps {
    "Nome": string;
    "E-mail": string;
    "Telefone1": string;
    "Telefone2": string;
    "Última interação": string;
    "Turma": string
}

function MakeExcelStudents({ queryurlExcel, isCourseSelected }: MakeExcelStudentsProps) {
    const [isLoading, setIsLoading] = useState(false);


    async function makeExcel() {
        try {
            if (!isCourseSelected) {
                return
            }

            setIsLoading(true)
            const { data: response } = await api.get(`/students/?${queryurlExcel}`)
            let rowsFormatter: RowsFormatterProps[] = []

            for (let ii of response.data) {
                rowsFormatter = [...rowsFormatter, {
                    "Nome": ii.name,
                    "E-mail": ii.email,
                    "Última interação": ii.last_interaction ? format(new Date(ii.last_interaction), "dd/MM/yy HH:mm") : "Nunca Acessou",
                    "Telefone1": ii.phone,
                    "Telefone2": ii.cellphone,
                    "Turma": ii.classroom_name
                }]
            }

            const workSheet: any = XLSX.utils.json_to_sheet(rowsFormatter)
            const workBook = XLSX.utils.book_new()

            XLSX.utils.book_append_sheet(workBook, workSheet, 'alunos')

            XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' })
            XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' })
            XLSX.writeFile(workBook, 'alunos.xlsx')
            setIsLoading(false)
        } catch (err) {
            setIsLoading(false)
        }
    }

    return (
        <Tooltip title={isCourseSelected ? "Gerar Excel" : "Selecione algum curso"} aria-label="pdf">
            <IconButton onClick={makeExcel} edge="start" color="inherit" aria-label="menu">
                {
                    isLoading ?
                        <ClipLoader color="#fcca50" loading={isLoading} size={20} />
                        :
                        <SiMicrosoftexcel color={isCourseSelected ? "#68D391" : "#FF6585"} />
                }

            </IconButton>
        </Tooltip>
    )
}

export { MakeExcelStudents }