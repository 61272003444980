import { Flex } from '@chakra-ui/react';
import React from 'react';

// import { Container } from './styles';

interface CardSemaphoreProps {
    type?: number;
}

const CardSemaphore: React.FC<CardSemaphoreProps> = ({ type }) => {
    return (
        <Flex h="100%" w={200} justifyContent="center" alignItems="center">
            <Flex h="16px" w="16px" bg="#E1E1E1" borderRadius="50px" mr="10px" />
            <Flex h="16px" w="16px" bg={type === 1 ? "#FCCA50" : "#E1E1E1"} borderRadius="50px" mr="10px" />
            <Flex h="16px" w="16px" bg={type === 2 ? "#56E556" : "#E1E1E1"} borderRadius="50px" mr="10px" />
        </Flex>
    )
}

export default CardSemaphore;