import { action } from "typesafe-actions"

export function signInRequest({ email, password, setErrorMessage }: { email: string, password: string, setErrorMessage: (arg: string) => void }) {
    return action('@auth/SIGN_IN_REQUEST', { email, password, setErrorMessage })
}

export function signInSuccess(token: string) {
    return action('@auth/SIGN_IN_SUCCESS', { token })
}

export function signOut() {
    return action('@auth/SIGN_OUT')
}

export function loadingAuth(state:boolean) {
    return action("@auth/LOADING",{state})
}