import { memo } from 'react'
import { Text, Box, Avatar, Flex } from '@chakra-ui/react'
import { Grid } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { useStyles } from './styles'
import BookRead from '../../assets/logo/book-read-user.svg'
import { useHistory } from "react-router-dom"
import { BsSearch } from 'react-icons/bs';


interface GridStudentsProps {
    _id: number;
    name: string;
    email: string;
    contents_started: number;
    contents_finished: number;
    phone: string;
    cellphone: string;

    percentage_finished: number;
    last_interaction: string | null;
    matchesDownMd: boolean;
    matchesDownSm: boolean;
    categories: string;
    totalContents: number;
    backRouter: "email" | "students";

}

function Students({ _id,
    backRouter,
    matchesDownMd,
    matchesDownSm,
    name,
    email,
    phone,
    cellphone,

    contents_started,
    contents_finished,
    percentage_finished,
    totalContents }: GridStudentsProps) {
    const classes = useStyles()
    const history = useHistory()

    function Home() {
        return history.push(`/student/${_id}/rank`)
    }


    return (
        <Grid container className={classes.gridContainer}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >
                <Box display="flex" flexDirection="row" >
                    <Box>
                        <Tooltip title={_id} aria-label="pdf">
                            <Flex w="50px" h="50px" bg="primary" align="center" justify="center" borderRadius="25px">
                                <Avatar boxSize="30px" name={name} bg="rgba(0,0,0,0)" color="white" width="20px" src={BookRead} />
                            </Flex>
                        </Tooltip>
                    </Box>
                    <Box ml="4" >
                        <Text fontSize="16px" fontWeight="bold" color="font">{name}</Text>
                        <Text color="font">{email}</Text>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >
                <Flex w="100%" direction="column" alignItems="center">
                    <Text fontSize="16px" color="font" fontWeight="bold">Telefone</Text>
                    <Text fontSize="16px" color="font">{phone}</Text>
                    <Text fontSize="16px" color="font">{cellphone}</Text>
                </Flex>
            </Grid>
            <Grid item xs={12} sm={12} md={1} lg={1} xl={1} >
                <Tooltip title="Conteúdos finalizado" aria-label="Finalizados">

                    <Flex w="30px" h="30px" bg="green.500" borderRadius="25px" justifyContent="center" alignItems="center">
                        <Text color="#fff" fontSize={contents_finished > 999 ? "12px" : "14px"}>{contents_finished}</Text>
                    </Flex>
                </Tooltip>
            </Grid>
            <Grid item xs={12} sm={12} md={1} lg={1} xl={1} >
                <Tooltip title="Conteúdos iniciados" aria-label="Finalizados">
                    <Flex w="30px" h="30px" bg="primary" borderRadius="25px" justifyContent="center" alignItems="center">
                        <Text color="font" fontSize={contents_started > 999 ? "12px" : "14px"}>{contents_started}</Text>
                    </Flex>
                </Tooltip>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >
                <Box display="flex" flexDirection="column" >
                    <Text fontWeight="bold" color="font">Porcentagem</Text>

                    <Tooltip title={`Total de conteúdos ${totalContents}`} aria-label="Finalizados">
                        <Flex alignItems="center">
                            <Flex w="100px" h="12px" bg="gray.300" borderRadius="20px" mr="15px">
                                <Flex
                                    w={`${percentage_finished.toFixed(2)}%`}
                                    h="12px"
                                    bg={percentage_finished < 20 ? "red.400" : percentage_finished < 60 ? "primary" : "green.500"}
                                    borderRadius="20px" />
                            </Flex>
                            <Text color="font">{`${percentage_finished.toFixed(2)} %`}</Text>
                        </Flex>
                    </Tooltip>
                </Box>
            </Grid>
            <Grid item xs={12} sm={1} md={1} lg={1} xl={1}  >
                <Flex flexDirection="column" h="100%" justifyContent="center" alignItems="center" cursor="pointer" onClick={Home}>
                    <BsSearch size={26} />
                </Flex>
            </Grid>

            <Grid item xs={12} >
                <Box h="1px" w="100%" bg="border" mt="15px" />
            </Grid>
        </Grid>
    )

}
const GridRankStudents = memo(Students)
export { GridRankStudents }