import { useEffect, useState } from "react";
import { Box, CircularProgress, Flex, Text } from "@chakra-ui/react";
import { api } from "../../services/api";
import { StudentsHttp } from "../../services/api/resource";
import { CardContent } from "../CardContent";
import { ContentsProps } from "./types";

interface CardCourseProps {
    title: string;
    studentId: number;
    categoryId: string;
    courseId: string;
    chapterId: string;
}

function CardChapter({
    title,
    studentId,
    categoryId,
    courseId,
    chapterId,
}: CardCourseProps) {
    const [loadingContent, setLoadingContent] = useState(false);
    const [contents, setContents] = useState<ContentsProps[]>([]);
    useEffect(() => {
        setLoadingContent(true);
        (async () => {
            const contentsCourse = await new StudentsHttp(
                api
            ).contentsByChapterSort({
                studentId,
                categoryId,
                courseId,
                chapterId,
            });

            setContents(contentsCourse);
            setLoadingContent(false);
        })();
    }, [courseId]);

    return (
        <Flex direction="column" w="100%" mb="25px">
            <Text fontSize="22px">{title}</Text>
            {loadingContent ? (
                <Box>
                    <CircularProgress isIndeterminate color="primary" />
                    Carregando Conteúdo
                </Box>
            ) : (
                contents.map((item, index) => (
                    <CardContent
                        key={index}
                        title={item.name}
                        amountOfViewing={item.views}
                        contentId={item._id}
                        dateStarted={item.started}
                        dateFinished={item.finished}
                        contentState={item.state}
                    />
                ))
            )}
        </Flex>
    );
}

export { CardChapter };
