import { useEffect, useState } from "react";
import { Grid, TextField, Button } from "@material-ui/core";

import { Text, Box, Flex, Image, CircularProgress } from "@chakra-ui/react";
import Pagination from "@material-ui/lab/Pagination";

import { InputSelect } from "../../components/InputSelect";
import { api } from "../../services/api";
import queryString from "query-string";
import * as dateFns from "date-fns";

import { PerPage } from "../../components/PerPage";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import BookReadPrimary from "../../assets/logo/book-read-user-primary.svg";

import { GridStudents } from "../../components/GridStudents";
import { MakeExcelStudents } from "../../components/MakeExcelStudents";
import { CategoryOptionHttp, StudentsHttp } from "../../services/api/resource";
import { CategoriesClassroomHttp } from "../../services/api/resource/category";
import { cleanObject } from "../../function/cleanObject";

import {
    CategoriesProps,
    ClassroomsProps,
    StudentsProps,
    StudentPageProps,
} from "./types";

export function Students({
    emailDrawer,
    studentEmail,
    isSearched,
    loading,
}: StudentPageProps) {
    const [categories, setCategories] = useState<CategoriesProps[]>([]);
    const [loadingCategories, setLoadingCategories] = useState<boolean>(false);
    const [categoryId, setCategoryId] = useState(
        localStorage.getItem("@codestudent/categoryId") || ""
    );

    const [classrooms, setClassrooms] = useState<ClassroomsProps[]>([]);
    const [loadingClasses, setLoadingClasses] = useState<boolean>(false);
    const [classroomId, setClassroomId] = useState(
        localStorage.getItem("@codestudent/classroomId") || ""
    );

    const [students, setStudents] = useState<StudentsProps[]>([]);
    const [loadingStudents, setLoadingStudents] = useState<boolean>(false);

    const perPageArray = [
        { _id: 5, name: "5" },
        { _id: 10, name: "10" },
        { _id: 20, name: "20" },
        { _id: 30, name: "30" },
        { _id: 50, name: "50" },
        { _id: 100, name: "100" },
    ];
    const [perPage, setPerPage] = useState(10);

    const [page, setPage] = useState(1);
    const [meta, setMeta] = useState({ total: 0, lastPage: 0 });

    const [endDates, setEndDates] = useState<
        { _id: string | number; day: number | string; name: string }[]
    >([]);
    const [endDateId, setEndDateId] = useState<number | string>(
        localStorage.getItem("@codestudent/endDateId") || ""
    );

    const [urlQueryExcel, setUrlQueryExcel] = useState("");
    const theme = useTheme();

    function searchDateEndById() {
        const dataWithoutAccess = [
            { _id: 3, name: "3 Dias" },
            { _id: 15, name: "15 Dias" },
            { _id: 21, name: "21 Dias" },
            { _id: 30, name: "30 Dias" },
            { _id: 60, name: "60 Dias" },
            { _id: 90, name: "90 Dias" },
        ];
        const createdListWithDateEnd = dataWithoutAccess.map((item, index) => {
            return {
                _id: index + 1,
                day: dateFns.subDays(new Date(), item._id || 0).toISOString(),
                name: item.name,
            };
        });

        const formattedData = [
            { _id: 0, day: 0, name: "Todos" },
            ...createdListWithDateEnd,
            { _id: "null", day: "null", name: "Nunca Acessaram" },
        ];
        const endDateIso = formattedData.find((item) => item._id === endDateId);
        if (endDateIso) {
            return endDateIso?.day.toString();
        }
        return 0;
    }

    useEffect(() => {
        (async () => {
            setLoadingCategories(true);
            const categoryOption = await new CategoryOptionHttp(api).list();

            setCategories(categoryOption);

            const dataWithoutAccess = [
                { _id: 3, name: "3 Dias" },
                { _id: 15, name: "15 Dias" },
                { _id: 21, name: "21 Dias" },
                { _id: 30, name: "30 Dias" },
                { _id: 60, name: "60 Dias" },
                { _id: 90, name: "90 Dias" },
            ];
            const formattedData = dataWithoutAccess.map((item, index) => {
                const day = dateFns
                    .subDays(new Date(), item._id || 0)
                    .toISOString();

                return { _id: index + 1, day, name: `${item.name}` };
            });
            setEndDates([
                { _id: 0, day: 0, name: "Todos" },
                ...formattedData,
                { _id: "null", day: "null", name: "Nunca Acessaram" },
            ]);
            setLoadingCategories(false);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            setLoadingClasses(true);
            const responseTurma = await new CategoriesClassroomHttp(
                api,
                categoryId
            ).classroom(categoryId);
            const classroomSorted = responseTurma.sort((prev, next) =>
                prev.name.localeCompare(next.name)
            );
            setClassrooms([{ _id: "0", name: "Todas" }, ...classroomSorted]);
            setLoadingClasses(false);
        })();
    }, [categoryId]);

    useEffect(() => {
        (async () => {
            setLoadingStudents(true);
            setPage(1);
            const responseTurma = await new StudentsHttp(api).filter({
                categoryId,
                endDateId: searchDateEndById(),
                classroomId,
                page: 1,
                perPage,
            });

            const options = {
                category_id: categoryId || null,
                date_end: searchDateEndById() || null,
                per_page: 999999,
                page: 1,
                classroom_id: classroomId || null,
            };
            setUrlQueryExcel(queryString.stringify(cleanObject(options)));

            setStudents(responseTurma.data);
            setMeta(responseTurma.meta);
            setLoadingStudents(false);
        })();
    }, [categoryId, classroomId, endDateId, perPage]);

    useEffect(() => {
        (async () => {
            const responseTurma = await new StudentsHttp(api).filter({
                categoryId,
                endDateId: searchDateEndById(),
                classroomId,
                page,
                perPage,
            });

            const options = {
                category_id: categoryId || null,
                date_end: searchDateEndById() || null,
                per_page: 999999,
                page: page || 1,
                classroom_id: classroomId || null,
            };

            setUrlQueryExcel(queryString.stringify(cleanObject(options)));

            setStudents(responseTurma.data);
            setMeta(responseTurma.meta);
        })();
    }, [page]);

    async function handleChangeCleanSearch() {
        setCategoryId("");
        setClassroomId("");
        setPerPage(10);
        setEndDateId("");
        setPage(1);
        setClassrooms([]);
        setMeta({ total: 0, lastPage: 0 });

        localStorage.setItem("@codestudent/categoryId", "");
        localStorage.setItem("@codestudent/classroomId", "");
        localStorage.setItem("@codestudent/endDateId", "");

        const responseTurma = await new StudentsHttp(api).filter({
            categoryId: "",
            endDateId: "",
            classroomId: "",
            page: 1,
            perPage: 10,
        });

        setStudents(responseTurma.data);
        setMeta(responseTurma.meta);

        const options = {
            category_id: categoryId || null,
            date_end: searchDateEndById() || null,
            per_page: 999999,
            page: page || 1,
            classroom_id: classroomId || null,
        };
        setUrlQueryExcel(queryString.stringify(cleanObject(options)));
    }

    const handleChangePage = (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setPage(value);
    };

    const matches = useMediaQuery(theme.breakpoints.up("md"));
    const matchesDownMd = useMediaQuery(theme.breakpoints.down("md")) || false;
    const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"));

    function handleCourseId(valueId: string) {
        setCategoryId(valueId);
        localStorage.setItem("@codestudent/categoryId", valueId);
        setEndDateId("");
        setPage(1);
        setClassroomId("");
    }

    function handleClassroomId(valueId: string) {
        setClassroomId(valueId);
        localStorage.setItem("@codestudent/classroomId", valueId);
    }

    function handleEndDate(valueId: string) {
        setEndDateId(valueId);
        localStorage.setItem("@codestudent/endDateId", valueId);
    }

    return (
        <>
            <Box>
                <Box>
                    <Flex align="center" mb="10px">
                        <Image src={BookReadPrimary} ml="2" mr="2" />
                        <Text ml="2" fontSize="22px" fontWeight="semibold">
                            Alunos
                        </Text>
                    </Flex>
                </Box>
                <Box ml={matchesDownMd ? "10px" : "20px"}>
                    <Box mb="50px">
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                {loadingCategories ? (
                                    <Box>
                                        <CircularProgress
                                            isIndeterminate
                                            color="primary"
                                        />
                                        Carregando Cursos
                                    </Box>
                                ) : (
                                    <InputSelect
                                        title="Cursos"
                                        array={categories}
                                        value={categoryId}
                                        onChange={handleCourseId}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                {loadingClasses ? (
                                    <Box>
                                        <CircularProgress
                                            isIndeterminate
                                            color="primary"
                                        />
                                        Carregando Turmas
                                    </Box>
                                ) : (
                                    <InputSelect
                                        title="Turmas"
                                        array={classrooms}
                                        value={classroomId}
                                        onChange={handleClassroomId}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                <InputSelect
                                    title="Não acessam há"
                                    array={endDates}
                                    value={endDateId}
                                    onChange={handleEndDate}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    {!isSearched && !loadingStudents && !loading && (
                        <Flex mb="50px" direction="column">
                            <Flex align="center">
                                <Text
                                    fontSize="22px"
                                    fontWeight="semibold"
                                >{`${meta.total} Alunos`}</Text>

                                <Text ml="4" mr="4" fontSize="14px">
                                    {` - `}{" "}
                                </Text>
                                <Text
                                    fontSize="14px"
                                    cursor="pointer"
                                    color="subText"
                                    onClick={handleChangeCleanSearch}
                                >
                                    {`Limpar Filtro`}{" "}
                                </Text>
                                <Box ml="auto">
                                    <MakeExcelStudents
                                        isCourseSelected={!!categoryId}
                                        queryurlExcel={urlQueryExcel}
                                    />
                                </Box>
                            </Flex>
                            {meta.total === 0 && (
                                <Text mt="8">
                                    Não foram encontrados alunos para este
                                    filtro.
                                </Text>
                            )}
                        </Flex>
                    )}
                    {(loadingStudents || loading) && (
                        <Box>
                            <CircularProgress isIndeterminate color="primary" />
                            Carregando Alunos
                        </Box>
                    )}
                    {isSearched ? (
                        studentEmail[0] ? (
                            studentEmail.map((item) => (
                                <GridStudents
                                    active={item.active}
                                    backRouter="students"
                                    matchesDownMd={matchesDownMd}
                                    matchesDownSm={matchesDownSm}
                                    key={item._id}
                                    _id={item._id}
                                    name={item.name}
                                    email={item.email}
                                    phone={item.phone}
                                    cellphone={item.cellphone}
                                    categories={item.classroom_name}
                                    last_interaction={item.last_interaction}
                                />
                            ))
                        ) : (
                            <Text fontSize="22px">
                                o aluno com {emailDrawer} não foi encontrado,
                                por favor realize uma nova busca.
                            </Text>
                        )
                    ) : categoryId ? (
                        students.map((item) => (
                            <GridStudents
                                active={item.active}
                                backRouter="students"
                                matchesDownMd={matchesDownMd}
                                matchesDownSm={matchesDownSm}
                                key={item._id}
                                _id={item._id}
                                name={item.name}
                                email={item.email}
                                phone={item.phone}
                                cellphone={item.cellphone}
                                categories={item.classroom_name}
                                last_interaction={item.last_interaction}
                            />
                        ))
                    ) : (
                        categories.length > 0 && (
                            <Text fontSize="22px">
                                Você deve selecionar uma categoria para que os
                                alunos sejam exibidos.
                            </Text>
                        )
                    )}
                    {!isSearched && (
                        <Flex mt="4" justifyContent="center">
                            <Flex
                                flexDirection={matches ? "row" : "column"}
                                align="center"
                                ml={matches ? "auto" : "0px"}
                            >
                                <PerPage
                                    perPageArray={perPageArray}
                                    perPage={perPage}
                                    setPerPage={setPerPage}
                                />
                                <Box
                                    mt={matches ? "0px" : "15px"}
                                    mb={matches ? "0px" : "5px"}
                                >
                                    <Pagination
                                        count={meta.lastPage}
                                        page={page}
                                        onChange={handleChangePage}
                                        color="primary"
                                    />
                                </Box>
                            </Flex>
                        </Flex>
                    )}
                </Box>
            </Box>
        </>
    );
}
