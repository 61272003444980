import { rest } from 'msw'
import { API_HOST, API_HOST_TEST, IS_TEST } from '../../../constants';
import { student } from './response'

import {
    Students1001StatsCourseProps,
    StudentProps,
    StudentsProps,
    StudentsCategoryStatsCategory,
    StudentsCategoryCourseChapterContents,
    StudentsCategoryStatsCourse
} from './types'

const composeEndpoint = (endpoint: string) =>
    `${IS_TEST ? API_HOST_TEST : API_HOST}${endpoint}`;


export const students = [
    rest.get<undefined, Students1001StatsCourseProps[]>(composeEndpoint('students/1001/category/1001/stats-course'),
        (_req, res, ctx) => res(ctx.status(200), ctx.json(
            student['students/1001/stats-course']
        ))
    ),
    rest.get<undefined, StudentProps>(composeEndpoint('students/2'),
        (_req, res, ctx) => res(ctx.status(200), ctx.json(
            student['students/2']
        ))
    ),
    rest.get<undefined, StudentProps>(composeEndpoint('students/1'),
        (_req, res, ctx) => res(ctx.status(200), ctx.json(
            student['students/1']
        ))
    ),
    rest.get<undefined, StudentsProps>(composeEndpoint('students'),
        (_req, res, ctx) => res(ctx.status(200), ctx.json(
            student['students']
        ))
    ),
    rest.get<undefined, StudentsCategoryStatsCategory[]>(composeEndpoint('students/1/category/163/stats-category'),
        (_req, res, ctx) => res(ctx.status(200), ctx.json(
            student['students/1/category/163/stats-category']
        ))
    ),
    rest.get<undefined, StudentsCategoryStatsCategory[]>(composeEndpoint('students/2/category/163/stats-category'),
        (_req, res, ctx) => res(ctx.status(200), ctx.json(
            student['students/2/category/163/stats-category']
        ))
    ),
    rest.get<undefined, StudentsCategoryStatsCategory[]>(composeEndpoint('students/3/category/163/stats-category'),
        (_req, res, ctx) => res(ctx.status(200), ctx.json(
            student['students/3/category/163/stats-category']
        ))
    ),
    rest.get<undefined, StudentsCategoryStatsCategory[]>(composeEndpoint('students/4/category/163/stats-category'),
        (_req, res, ctx) => res(ctx.status(200), ctx.json(
            student['students/4/category/163/stats-category']
        ))
    ),
    rest.get<undefined, []>(composeEndpoint('students/163/category/163/course/163/chapter/163/contents'),
        (_req, res, ctx) => res(ctx.status(200), ctx.json(
            []
        ))
    ),
    rest.get<undefined, StudentsCategoryCourseChapterContents[]>(composeEndpoint('students/1/category/163/course/102/chapter/676/contents'),
        (_req, res, ctx) => res(ctx.status(200), ctx.json(
            student['students/1/category/163/course/102/chapter/676/contents']
        ))
    ),
    rest.get<undefined, StudentsCategoryStatsCourse[]>(composeEndpoint('students/1/category/163/stats-course'),
        (_req, res, ctx) => res(ctx.status(200), ctx.json(
            student['students/1/category/163/stats-course']
        ))
    ),

]
