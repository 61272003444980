import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useStyles } from "./styles";

interface CoursesProps {
    _id: number | string;
    name: string;
}

interface InputSelectProps {
    title: string;
    array: CoursesProps[];
    value: string | number;
    onChange?: (arg: string) => void;
}

function InputSelect({ title, array, value, onChange }: InputSelectProps) {
    const classes = useStyles();

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label" className={classes.input}>
                {title}
            </InputLabel>
            <Select
                defaultValue=""
                className={classes.input}
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={array.length ? value : ""}
                onChange={(e) => onChange!(e.target.value as string)}
            >
                {array.length ? (
                    array.map((item, index) => (
                        <MenuItem key={index} value={item._id}>
                            {item.name}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem value="">{`Sem ${title} cadastrados`}</MenuItem>
                )}
            </Select>
        </FormControl>
    );
}

export { InputSelect };
