import { AxiosInstance } from "axios";
import { api } from "../..";
import { HttpResource } from "../../httpResource";
import { CourseGetHttpResponse } from "./types";

export class CoursesHttp extends HttpResource {
    constructor(public http: AxiosInstance = api) {
        super(http, "");
    }
    async find(): Promise<CourseGetHttpResponse[]> {
        const data = await super.get<Promise<CourseGetHttpResponse[]>>(
            `/students/stats/enrollments`
        );
        return data;
    }
}

