export const categorie = {
    "categories/1001/stats-courses": [
        {
            "_id": 103,
            "name": "Microsserviço de Encoder de Vídeo com Go Lang",
            "contents_total": 44
        },
        {
            "_id": 119,
            "name": "Observabilidade",
            "contents_total": 47
        },
    ],
    'categories/163/stats-courses': [
        {
            "_id": 125,
            "name": "Microsserviço - Aplicação do Assinante com React.js (Front-end)",
            "contents_total": 41,
            "percentage": 0,
            "last_interaction": null,
            "contents_finished": 0
        },
        {
            "_id": 117,
            "name": "Kubernetes",
            "contents_total": 53,
            "percentage": 0,
            "last_interaction": null,
            "contents_finished": 0
        }
    ],
    "categories/options": [
        {
            "_id": 18,
            "name": "Curso de PHP"
        },
        {
            "_id": 17,
            "name": "Cursos SON - Acesso a todos os cursos"
        }
    ],
    'categories/13': {
        _id: 13,
        name: "Desenvolvimento de Aplicações Modernas e Escaláveis com Microserviços",
        classrooms: [
            {
                _id: 9,
                name: "Turma 9",
                created_at: "2021-07-17T16:29:47.000Z"
            },
            {
                _id: 10,
                name: "Turma 10",
                created_at: "2021-07-17T16:29:47.000Z"
            }
        ],
        courses: []
    },
    'categories/163/courses/102/chapters/676/contents': [
        {
            "_id": 5917,
            "name": "Introdução",
            "order": 1
        },
        {
            "_id": 5918,
            "name": "Simulador de Comportamento",
            "order": 2
        },
        {
            "_id": 5919,
            "name": "Confiabilidade",
            "order": 3
        },
        {
            "_id": 5920,
            "name": "Rabbitmq Ui",
            "order": 4
        }
    ],
    'categories/163/courses': [
        {
            "_id": 121,
            "name": "Apache Kafka"
        },
        {
            "_id": 124,
            "name": "Arquitetura do projeto prático - Codeflix"
        },
        {
            "_id": 123,
            "name": "Arquitetura hexagonal"
        },
        {
            "_id": 108,
            "name": "Autenticação e Keycloak"
        },
        {
            "_id": 114,
            "name": "Autenticação entre os microsserviços"
        },
        {
            "_id": 116,
            "name": "Comunicação"
        },
        {
            "_id": 120,
            "name": "Deploy nos Cloud Providers (em breve)"
        },
        {
            "_id": 110,
            "name": "Docker"
        },
        {
            "_id": 129,
            "name": "Domain Driven Design"
        },
        {
            "_id": 112,
            "name": "Fundamentos de Arquitetura de Software"
        },
        {
            "_id": 115,
            "name": "Integração contínua"
        },
        {
            "_id": 117,
            "name": "Kubernetes"
        },
        {
            "_id": 105,
            "name": "Microsserviço - API do Catálogo com Node.JS (Back-end)"
        },
        {
            "_id": 125,
            "name": "Microsserviço - Aplicação do Assinante com React.js (Front-end)"
        },
        {
            "_id": 126,
            "name": "Microsserviço - Assinaturas com Django (Back-end)  - (em breve)"
        },
        {
            "_id": 103,
            "name": "Microsserviço de Encoder de Vídeo com Go Lang"
        },
        {
            "_id": 107,
            "name": "Microsserviço: Catálogo de vídeos com Laravel ( Back-end )"
        },
        {
            "_id": 109,
            "name": "Microsserviço: Catálogo de vídeos com React ( Front-end )"
        },
        {
            "_id": 119,
            "name": "Observabilidade"
        },
        {
            "_id": 113,
            "name": "Padrões e técnicas avançadas com Git e Github"
        },
        {
            "_id": 102,
            "name": "RabbitMQ"
        },
        {
            "_id": 118,
            "name": "Service Mesh com Istio"
        },
        {
            "_id": 130,
            "name": "Terraform"
        }
    ],
    'categories/163/courses/102/chapters': [
        {
            "_id": 676,
            "name": "Iniciando",
            "order": 0
        }
    ]
}