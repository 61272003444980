import { Flex, Text, Image } from "@chakra-ui/react";
import { Grid } from "@material-ui/core";
import BookReadWhite from "../../assets/logo/book-read-user-white.svg";
import { format } from "date-fns";
import { BsArrowLeft } from "react-icons/bs";

interface HeaderBarProps {
    name: string;
    email: string;
    active?: boolean;
    lastAccess: string | null;
    BsArrowLeftFunction?: () => void;
}

function HeaderBar({
    BsArrowLeftFunction,
    name,
    active,
    email,
    lastAccess,
}: HeaderBarProps) {
    return (
        <Grid container>
            <Grid item xs={12} sm={8} md={8} lg={6} xl={6}>
                <Flex direction="row" h="50px" align="center">
                    {BsArrowLeftFunction && (
                        <Flex
                            mr="10px"
                            onClick={BsArrowLeftFunction}
                            cursor="pointer"
                        >
                            <BsArrowLeft size={35} />
                        </Flex>
                    )}
                    <Flex
                        direction="row"
                        h="50px"
                        w="50px"
                        justify="center"
                        align="center"
                    >
                        <Flex
                            bg={active ? "green.500" : "red.300"}
                            p="2"
                            h="50px"
                            w="50px"
                            borderRadius="50px"
                        >
                            <Image src={BookReadWhite} alt="logo" />
                        </Flex>
                    </Flex>
                    <Flex
                        pl="5"
                        direction="column"
                        justifyContent="space-around"
                        h="50px"
                        w="100%"
                    >
                        <Text
                            fontWeight="bold"
                            fontSize="22px"
                            color={active ? "font" : "fontDisabled"}
                        >
                            {name}
                        </Text>
                        <Text
                            fontSize="14px"
                            color={active ? "font" : "fontDisabled"}
                        >
                            {email}
                        </Text>
                    </Flex>
                </Flex>
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={3} xl={3}>
                <Flex
                    pl="5"
                    direction="column"
                    justifyContent="space-around"
                    h="50px"
                    w="100%"
                >
                    <Text
                        fontWeight="semibold"
                        fontSize="16px"
                        color={active ? "font" : "fontDisabled"}
                    >
                        Último Acesso
                    </Text>
                    <Text
                        fontSize="14px"
                        color={
                            active
                                ? lastAccess
                                    ? "black"
                                    : "red.500"
                                : "fontDisabled"
                        }
                    >
                        {lastAccess
                            ? format(new Date(lastAccess), "dd/MM/yy HH:mm")
                            : "Nunca Acessou"}
                    </Text>
                </Flex>
            </Grid>

            <Grid item xs={12} sm={2} md={2} lg={3} xl={3}>
                <Flex
                    pl="5"
                    direction="column"
                    justifyContent="space-around"
                    h="50px"
                    w="100%"
                >
                    <Text fontWeight="semibold" fontSize="16px">
                        Status
                    </Text>
                    <Text fontSize="14px" color={active ? "green" : "red"}>
                        {active ? "Ativo" : "Bloqueado"}
                    </Text>
                </Flex>
            </Grid>

            <Grid item xs={12}>
                <Flex h="1px" bg="border" w="100%" mt="5" />
            </Grid>
        </Grid>
    );
}

export { HeaderBar };
