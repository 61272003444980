import React, { ReactNode } from "react";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import MailIcon from "@material-ui/icons/Mail";
import useStyles from "./styles";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Container from "@material-ui/core/Container";

import { ItemIcon } from "./ListItemIcon";
import { Avatar, Flex, Text, Stack } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/createStore";
import ExitToApp from "@material-ui/icons/ExitToApp";
import { useDispatch } from "react-redux";
import LogoFullCycle from "../../assets/logo/fullcycle.svg";
import { Image } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import * as actionsAuth from "../../store/modules/auth/actions";

import InputBase from "@material-ui/core/InputBase";

import { menu } from "./menu";

import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

interface MiniDrawerProps {
    children?: ReactNode | any;
    modeTest?: string;
    screenId: number;
    studentEmail?: any;
    setStudentEmail?: (agr: any) => void;
    inputEmail?: string;
    setInputEmail?: (arg: any) => void;
    isSearched?: boolean;
    searchEmail?: () => void;
    cleanEmail?: () => void;
    handleSubmit?: () => void;
}

export default function MiniDrawer({
    searchEmail,
    cleanEmail,
    screenId,
    children,
    isSearched,
    setInputEmail,
    handleSubmit,
    inputEmail,
}: MiniDrawerProps) {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const [openSm, setOpenSm] = React.useState(false);

    const dispatch = useDispatch();
    const { email, name } = useSelector((state: StoreState) => state.user);
    const history = useHistory();

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerOpenSm = () => {
        setOpenSm(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const handleDrawerCloseSm = () => {
        setOpenSm(false);
    };

    const matches = useMediaQuery(theme.breakpoints.up("md"));

    const handleLogout = () => {
        dispatch(actionsAuth.signOut());
    };

    function handleStudents() {
        history.push("/students");
    }
    function reloadPage(route: string) {
        history.push(`/${route}`);
    }

    function handleSearchEmail() {
        searchEmail!();
    }

    function handleCleanSearchEmail() {
        cleanEmail!();
    }

    if (matches) {
        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, {
                                [classes.hide]: open,
                            })}
                        >
                            <MenuIcon className={classes.iconColorAppBar} />
                        </IconButton>
                        {searchEmail && (
                            <Flex
                                w={["100%", "100%", "50%"]}
                                borderBottom="2px"
                                borderColor="primary"
                            >
                                <InputBase
                                    value={inputEmail}
                                    onChange={(e) => {
                                        handleSubmit!();
                                        setInputEmail!(e.target.value);
                                    }}
                                    style={{ color: "#fff" }}
                                    className={classes.input}
                                    placeholder="E-mail"
                                    inputProps={{
                                        "aria-label":
                                            "buscar aluno por email",
                                    }}
                                    onKeyDown={(e) => {
                                        if (
                                            e.code === "Enter" ||
                                            e.code === "NumpadEnter"
                                        ) {
                                            handleSearchEmail();
                                        }
                                    }}
                                />
                                <IconButton
                                    type="submit"
                                    className={classes.iconButton}
                                    aria-label="search"
                                >
                                    {isSearched ? (
                                        <ClearIcon
                                            onClick={handleCleanSearchEmail}
                                            style={{ fill: "#B8B8B8" }}
                                        />
                                    ) : (
                                        <SearchIcon
                                            onClick={handleSearchEmail}
                                            style={{ fill: "#B8B8B8" }}
                                        />
                                    )}
                                </IconButton>
                            </Flex>
                        )}

                        <Flex ml="auto">
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleLogout}
                                edge="start"
                            >
                                <ExitToApp className={classes.iconColor} />
                            </IconButton>
                        </Flex>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                >
                    <div className={classes.toolbar}>
                        <Flex
                            w="100%"
                            mt="-16px"
                            h="80px"
                            bg="black"
                            align="center"
                        >
                            <Flex
                                mt="16px"
                                justify="space-around"
                                align="center"
                                w="100%"
                            >
                                <Image
                                    src={LogoFullCycle}
                                    onClick={handleStudents}
                                    className={classes.image}
                                />

                                <MenuIcon
                                    onClick={handleDrawerClose}
                                    className={classes.iconColorAppBar}
                                />
                            </Flex>
                        </Flex>
                    </div>
                    {open && (
                        <Stack spacing="2" align="center" mt="5">
                            <Avatar
                                size="xl"
                                name={name}
                                src={name}
                                bg="primary"
                                color="black"
                            />
                            <Text fontWeight="bold">{name}</Text>
                            <Text>{email}</Text>
                        </Stack>
                    )}
                    <List>
                        {menu.map((item, index) => (
                            <ItemIcon
                                active={index === screenId}
                                onClick={() => reloadPage(item.route)}
                                title={item.title}
                                icon={item.icon}
                                isImage={!!item.isImage}
                            />
                        ))}
                    </List>
                    <Divider />
                </Drawer>
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    {children}
                </main>
            </div>
        );
    } else if (!matches) {
        return (
            <>
                <Drawer
                    anchor="left"
                    open={openSm}
                    onClose={handleDrawerCloseSm}
                >
                    <div
                        className={classes.list}
                        role="presentation"
                        onClick={handleDrawerCloseSm}
                        onKeyDown={handleDrawerCloseSm}
                    >
                        <Stack spacing="2" align="center">
                            <Avatar
                                size="xl"
                                name={name}
                                src={name}
                                bg="primary"
                                color="black"
                            />
                            <Text fontWeight="bold">{name}</Text>
                            <Text>{email}</Text>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleLogout}
                                edge="start"
                            >
                                <ExitToApp className={classes.iconColorSm} />
                            </IconButton>
                        </Stack>

                        <List>
                            <ItemIcon
                                active
                                onClick={handleStudents}
                                title="Alunos"
                                icon={MailIcon}
                            />
                        </List>
                        <Divider />
                    </div>
                </Drawer>
                <AppBar className={classes.appBarMobile}>
                    <Toolbar>
                        {searchEmail && (
                            <Flex
                                w="80%"
                                borderBottom="2px"
                                borderColor="primary"
                            >
                                <InputBase
                                    value={inputEmail}
                                    onChange={(e) => {
                                        handleSubmit!();
                                        setInputEmail!(e.target.value);
                                    }}
                                    className={classes.input}
                                    placeholder="E-mail"
                                    inputProps={{
                                        "aria-label":
                                            "buscar aluno por email",
                                    }}
                                    onKeyDown={(e) => {
                                        if (
                                            e.code === "Enter" ||
                                            e.code === "NumpadEnter"
                                        ) {
                                            handleSearchEmail();
                                        }
                                    }}
                                />
                                <IconButton
                                    type="submit"
                                    className={classes.iconButton}
                                    aria-label="search"
                                >
                                    {isSearched ? (
                                        <ClearIcon
                                            onClick={handleCleanSearchEmail}
                                            style={{ fill: "#B8B8B8" }}
                                        />
                                    ) : (
                                        <SearchIcon
                                            onClick={handleSearchEmail}
                                            style={{ fill: "#B8B8B8" }}
                                        />
                                    )}
                                </IconButton>
                            </Flex>
                        )}
                        <Flex ml="auto">
                            <MenuIcon
                                onClick={handleDrawerOpenSm}
                                className={classes.iconColorAppBar}
                            />
                        </Flex>
                    </Toolbar>
                </AppBar>
                <Toolbar id="back-to-top-anchor" />
                <Container>
                    <Flex mt="10px" ml="10px">
                        {children}
                    </Flex>
                </Container>
            </>
        );
    } else {
        return <>{children}</>;
    }
}
