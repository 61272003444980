import { Route, RouteProps, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { StoreState } from "../store/createStore";
import { api } from "../services/api";
import { store } from "../store";
import { signOut } from "../store/modules/auth/actions";
interface RouteTypes extends RouteProps {
    isPrivate?: boolean;
    component: React.ComponentType;
}

const Router: React.FC<RouteTypes> = ({
    isPrivate = false,
    component: Component,
    ...rest
}) => {
    const { dispatch } = store;
    const { isSigned } = useSelector((state: StoreState) => state.user);
    if (!!!isSigned) {
        dispatch(signOut());
    }
    return (
        <Route
            path="/"
            {...rest}
            render={() => {
                return isPrivate === isSigned ? (
                    <Component />
                ) : (
                    <Redirect to={{ pathname: isPrivate ? "/" : "students" }} />
                );
            }}
        />
    );
};

export default Router;
