import { applyMiddleware, createStore, Middleware, Reducer } from "redux";
import { AuthAction, AuthState } from "./modules/auth/types";
import { UserAction, UserState } from "./modules/user/types";
import { PersistState } from "redux-persist";

export interface StoreState {
    _persist: PersistState;
    auth: AuthState;
    user: UserState;
}

export type StoreAction = AuthAction | UserAction;

export default (
    redurcers: Reducer<StoreState, StoreAction>,
    middlewares: Middleware[]
) => {
    const enhancer = applyMiddleware(...middlewares);
    return createStore(redurcers, enhancer);
};
