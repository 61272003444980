import { AxiosInstance } from "axios";
import { CategoryCourseHttp } from "..";
import { HttpResource } from "../../httpResource";
import {
    Course,
    StudentSortPercentage,
    StudentCourseStats,
    StudentsFilterProps,
    ContentsByChapterSortProps,
    StudentsPromiseProps,
    StudentsProps,
    ContentsByChapterProps,
    StudentChapterAllDataProps,
    StudentByChapterProps,
    StudentsFilterEmailProps,
} from "./types";
import queryString from 'query-string';
import { cleanObject } from "../../../../function/cleanObject";

export class StudentCourseHttp extends HttpResource {
    studentId: number;
    constructor(public http: AxiosInstance, studentId: number) {
        super(http, `students/${studentId}`)
        this.studentId = studentId;
    }
}


export class StudentStatsCourseHttp extends HttpResource {
    categoryId: number;
    studentId: number;
    constructor(public http: AxiosInstance, studentId: number, categoryId: number) {
        super(http, `/students/${studentId}/category/${categoryId}/stats-course`);
        this.categoryId = categoryId;
        this.studentId = studentId;
    }

    async sortPercentage(): Promise<StudentSortPercentage[]> {
        const courseHttp = new CategoryCourseHttp(this.http, this.categoryId);
        const courses = await courseHttp.list<Course[]>();
        const courseStats = await super.list<StudentCourseStats[]>();

        const stats = courses.map((course) => {
            const stats = {
                _id: course._id,
                name: course.name,
                percentageFinished: 0,
                percentageStarted: 0,
                contentsFinished: 0,
                contentsStarted: 0,
                contentsTotal: 0,
                lastInteraction: "",
            };

            const courseStat = courseStats.find(stats => course._id === stats._id);

            if (courseStat) {
                stats.contentsStarted = courseStat.contents_started;
                stats.contentsFinished = courseStat.contents_finished;
                stats.contentsTotal = course.contents_total;

                stats.percentageFinished = Number((
                    (courseStat.contents_finished * 100) /
                    course.contents_total).toFixed(2));

                stats.percentageStarted = course.contents_total - courseStat.contents_finished ? Number(((
                    courseStat.contents_started * 100) /
                    (course.contents_total - courseStat.contents_finished)).toFixed(2)) : 0.00;

                stats.lastInteraction = courseStat.last_interaction;
            }

            return stats;
        });
        return stats.sort((a: StudentSortPercentage, b: StudentSortPercentage) => {
            return b.percentageFinished - a.percentageFinished
        });
    }
}

export class StudentsHttp extends HttpResource {
    constructor(public http: AxiosInstance) {
        super(http, "");
    }


    async filter({ categoryId, endDateId, perPage, page, classroomId }: StudentsFilterProps): Promise<StudentsPromiseProps> {
        if (!categoryId) {
            return {
                data: [],
                meta: {
                    total: 0,
                    lastPage: 0,
                }
            }
        }


        const options = {
            category_id: categoryId || null,
            date_end: endDateId || null,
            per_page: perPage,
            page: page || 1,
            classroom_id: classroomId || null,
        }
        const query = queryString.stringify(cleanObject(options))
        const { data, meta: { lastPage, total } } = await super.get<Promise<StudentsProps>>(`students/?${query}`)

        return { data, meta: { total, lastPage } }
    }
    async searchEmail({ emailStudent }: StudentsFilterEmailProps): Promise<StudentsPromiseProps> {
        if (!emailStudent) {
            return {
                data: [],
                meta: {
                    total: 0,
                    lastPage: 0,
                }
            }
        }


        const options = {
            email: emailStudent || null,

        }
        const query = queryString.stringify(cleanObject(options))
        const { data, meta: { lastPage, total } } = await super.get<Promise<StudentsProps>>(`students/?${query}`)

        return { data, meta: { total, lastPage } }
    }

    async contentsByChapterSort({ studentId, categoryId, courseId, chapterId }: ContentsByChapterSortProps): Promise<StudentChapterAllDataProps[]> {
        if (!studentId || !categoryId || !courseId || !chapterId) {
            return []
        }


        const contentsByChapter = await super.get<Promise<ContentsByChapterProps[]>>(`categories/${categoryId}/courses/${courseId}/chapters/${chapterId}/contents`)
        const studentByChapter = await super.get<Promise<StudentByChapterProps[]>>(`students/${studentId}/category/${categoryId}/course/${courseId}/chapter/${chapterId}/contents`)
        const chaptersAllData: StudentChapterAllDataProps[] = contentsByChapter.map((chapter) => {
            const chapterAllData: StudentChapterAllDataProps = {
                _id: chapter._id,
                name: chapter.name,
                order: chapter.order,
                started: null,
                finished: null,
                views: 0,
                state: 0,
            };

            const studentStat = studentByChapter.find(studentChapter => studentChapter._id === chapter._id);
            if (studentStat) {
                chapterAllData.started = studentStat.started;
                chapterAllData.finished = studentStat.finished || null;
                chapterAllData.views = studentStat.views || 0;
                chapterAllData.state = studentStat.finished ? 2 : studentStat.started ? 1 : 0;
            }
            return chapterAllData;
        });
        return chaptersAllData
    }
}
