import { useState } from "react";
import Drawer from "../../components/Drawer";
import { api } from "../../services/api";
import { HttpResource } from "../../services/api/httpResource";
import { Students as StudentsPage } from "./students";
import { StudentEmailProps, StudentsProps, QueryStudentProps } from "./types";
import queryString from "query-string";
import validator from "validator";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
export function Students() {
    const [inputEmail, setInputEmail] = useState("");
    const [isSearched, setIsSearched] = useState(false);

    const [studentEmail, setStudentEmail] = useState<StudentsProps[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    async function searchEmail() {
        if (!inputEmail || !validator.isEmail(inputEmail)) {
            toast.error("E-mail inválido.");
            return;
        }
        setLoading(true);
        const { data: student } = await new HttpResource(
            api,
            `students?${queryString.stringify({ email: inputEmail })}`
        ).list<QueryStudentProps>();
        if (!student[0]) {
            toast.error(
                `O aluno com ${inputEmail} não foi encontrado, por favor realize uma nova busca.`
            );
            setLoading(false);
            return;
        }
        setLoading(false);
        setIsSearched(true);
        setStudentEmail(student);
    }

    async function cleanEmail() {
        setInputEmail("");
        setIsSearched(false);
        setStudentEmail([]);
    }

    async function handleSubmit() {
        setIsSearched(false);
        setStudentEmail([]);
    }

    return (
        <Drawer
            screenId={0}
            setStudentEmail={setStudentEmail}
            studentEmail={studentEmail}
            inputEmail={inputEmail}
            isSearched={isSearched}
            setInputEmail={setInputEmail}
            searchEmail={searchEmail}
            cleanEmail={cleanEmail}
            handleSubmit={handleSubmit}
        >
            <StudentsPage
                emailDrawer={inputEmail}
                studentEmail={studentEmail}
                isSearched={isSearched}
                loading={loading}
            />
        </Drawer>
    );
}
