import React from 'react';
import { ChakraProvider } from '@chakra-ui/react'
import { ThemeProvider } from '@material-ui/core'
import { theme, themeMaterial } from './global/theme';
import { Routers } from './routers'
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux'
import { store, persistor } from './store'
import { ToastContainer } from 'react-toastify';
const App: React.FC = () => {
    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <ChakraProvider resetCSS theme={theme}>
                    <ThemeProvider theme={themeMaterial}>
                        <Routers />
                        <ToastContainer />
                    </ThemeProvider>
                </ChakraProvider>
            </PersistGate>
        </Provider>
    )
}

export default App;