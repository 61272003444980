import { forwardRef, ForwardRefRenderFunction } from 'react'
import { Box, Text, Input as ChrakraInput, InputProps as ChakraInputProps } from '@chakra-ui/react'


interface InputProps extends ChakraInputProps {
    name: string;
    label?: string;
    error: any;
    typePassword?: boolean;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> =
    ({ name, label, error, ...rest }, ref) => {

        return (
            <Box>
                {
                    label && <Text fontWeight="bold" fontSize="xl" color="white">{label}</Text>
                }
                <ChrakraInput name={name} id={name} _focus={{ borderColor: "primary", borderWidth: 2 }} bg="white" ref={ref} {...rest} />
                {
                    error && <Text color="red.500">{error.message}</Text>
                }
            </Box>
        )
    }

export const Input = forwardRef(InputBase)