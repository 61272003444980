import { useEffect, useState } from "react";
import { Box, CircularProgress, Flex, Text } from "@chakra-ui/react";
import Drawer from "../../components/Drawer";
import { HeaderBar } from "../../components/HeaderBar";
import { Grid } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import { api } from "../../services/api";
import { StudentCourseHttp } from "../../services/api/resource";
import { HttpResource } from "../../services/api/httpResource";
import {
    RouterParamsProps,
    StudentDataProps,
    StudentInformationProps,
    CoursesProps,
    ContentsProps,
} from "./types";

import { InputSelect } from "../../components/InputSelect";

import { CardChapter } from "../../components/CardChapter";

function StudentStatistic() {
    const { categorieParamId, courseParamId, ...paramsRouter } =
        useParams<RouterParamsProps>();
    const studentParamId = Number(paramsRouter.studentParamId);

    const [courses, setCourses] = useState<CoursesProps[]>([]);
    const [courseId, setCourseId] = useState(courseParamId);

    const [loadingChapters, setLoadingChapters] = useState(false);
    const [courseOfChapterId, setCourseOfChapterId] = useState(courseParamId);
    const [chapters, setChapters] = useState<CoursesProps[]>([]);

    const [loadStdData, setLoadStdData] = useState(false);
    const [studentData, setStudentData] = useState<StudentDataProps>({
        _id: 0,
        name: "",
        active: false,
        email: "",
        phone: "",
        cellphone: "",
        last_interaction: null,
    });

    const history = useHistory();

    useEffect(() => {
        setLoadStdData(true);
        setLoadingChapters(true);
        (async () => {
            const studentInformation = await new StudentCourseHttp(
                api,
                studentParamId
            ).list<StudentInformationProps>();
            setStudentData({
                _id: studentInformation._id,
                name: studentInformation.name,
                active: studentInformation.active,
                email: studentInformation.email,
                last_interaction: studentInformation.last_interaction,
                cellphone: studentInformation.cellphone,
                phone: studentInformation.phone,
            });

            const categorieCourse = await new HttpResource(
                api,
                `categories/${categorieParamId}/courses`
            ).list();
            setCourses(categorieCourse);

            const chapterCourse = await new HttpResource(
                api,
                `categories/${categorieParamId}/courses/${courseId}/chapters`
            ).list();
            setChapters(chapterCourse);
            setLoadStdData(false);
            setLoadingChapters(false);
        })();
    }, []);

    useEffect(() => {
        setLoadingChapters(true);
        (async () => {
            const chapterCourse = await new HttpResource(
                api,
                `categories/${categorieParamId}/courses/${courseId}/chapters`
            ).list();
            setChapters(chapterCourse);
            setCourseOfChapterId(courseId);
            setLoadingChapters(false);
        })();
    }, [courseId]);

    function BsArrowLeftFunction() {
        history.push(`/student/${studentParamId}/students`);
    }

    return (
        <Drawer screenId={0}>
            <Flex direction="column">
                {loadStdData ? (
                    <Box>
                        <CircularProgress isIndeterminate color="primary" />
                        Carregando Dados
                    </Box>
                ) : (
                    <HeaderBar
                        active={studentData.active}
                        BsArrowLeftFunction={() => BsArrowLeftFunction()}
                        name={studentData.name}
                        email={studentData.email}
                        lastAccess={studentData.last_interaction}
                    />
                )}
            </Flex>
            <Flex mt="46px">
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <InputSelect
                            title="Cursos"
                            array={courses}
                            value={courseId}
                            onChange={setCourseId}
                        />
                    </Grid>
                    {false && (
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Flex flexDirection="column" h="100%">
                                <Text fontSize="16px" fontWeight="semibold">
                                    Média do Capítulo
                                </Text>
                                <Flex alignItems="center" mt="auto">
                                    <Flex
                                        h="8px"
                                        bg="#dad8d8"
                                        borderRadius="10"
                                        w="100%"
                                        mr="20px"
                                    >
                                        <Flex
                                            h="8px"
                                            bg="#FCCA50"
                                            borderRadius="10"
                                            w="0px"
                                        />
                                    </Flex>
                                    <Text>%</Text>
                                </Flex>
                            </Flex>
                        </Grid>
                    )}
                </Grid>
            </Flex>
            <Flex mt="50px" direction="column">
                <Text fontSize="22px" fontWeight="semibold">
                    Capítulos
                </Text>
                <Flex maxW="100%" mt="35px">
                    <Grid container>
                        {loadingChapters ? (
                            <Box>
                                <CircularProgress
                                    isIndeterminate
                                    color="primary"
                                />
                                Carregando Capítulos
                            </Box>
                        ) : (
                            chapters.map((content, index) => (
                                <CardChapter
                                    key={index}
                                    title={content.name}
                                    studentId={studentParamId}
                                    categoryId={categorieParamId}
                                    courseId={courseOfChapterId}
                                    chapterId={`${content._id}`}
                                />
                            ))
                        )}
                    </Grid>
                </Flex>
            </Flex>
        </Drawer>
    );
}

export { StudentStatistic };
