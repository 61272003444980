import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import { useStyles } from './styles'

interface PerPageProps {
    perPageArray: any[];
    perPage: number;
    setPerPage: (arg: number) => void
}

const PerPage: React.FC<PerPageProps> = ({ perPageArray, perPage, setPerPage }) => {

    const classes = useStyles();

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">Item por página</InputLabel>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={perPage}
                onChange={(e) => setPerPage(e.target.value as number)}
            >
                {
                    perPageArray.map((item, index) => (
                        <MenuItem key={index} value={item._id}>{item.name}</MenuItem>
                    ))
                }

            </Select>
        </FormControl>
    )
}

export { PerPage };