import { Flex, Text } from '@chakra-ui/react'
import { Grid } from '@material-ui/core'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { api } from '../../services/api'
import { CategoryCourseHttp } from '../../services/api/resource'


interface CardProps {
    categoryId: number;
    studentId: number;
    categoriesName: string;
    setCourseDetails: (arg: any) => void;
}

function CardStatisticCategory({ categoriesName, studentId, categoryId, setCourseDetails }: CardProps) {
    const [categoryData, setCategoryData] = useState({ percentage: 0, lastInteraction: "" })

    useEffect(() => {
        let mounted = true
        async function Home() {
            const categoryCourse = await new CategoryCourseHttp(api, "").info({ studentId, categoryId })
            if (categoryCourse.last_interaction || categoryCourse.porcent) {

                if (mounted) {
                    setCategoryData({
                        percentage: categoryCourse.porcent,
                        lastInteraction: categoryCourse.last_interaction
                    })

                }
            }
        }
        Home()
        return () => { mounted = false }
    }, [])


    return (
        <Grid item xs={12} sm={6} md={6} lg={4} xl={2}>
            <Flex w="100%" h="140px" pt="2" pr="3">
                <Flex onClick={() => {
                    setCourseDetails({ id: categoryId, name: categoriesName })
                }}
                    direction="column"
                    cursor="pointer" w="100%" h="100%" p="4" borderRadius="2px" shadow="0px 2px 4px #ABABAB29" borderWidth="0.3px" borderColor="primary">
                    <Flex w="100%">
                        <Text fontSize="16px" fontWeight="semibold">{categoriesName}</Text>
                        <Text ml="auto" mr="5px" fontSize="14px" color={categoryData.lastInteraction ? "black" : "red"} fontWeight="semibold">{categoryData.lastInteraction ? format(new Date(categoryData.lastInteraction), "dd/MM/yy - HH:mm") : "Nunca Acessou"}</Text>
                    </Flex>
                    <Flex mt="auto" direction="column">
                        <Text mt="auto" fontWeight="bold">Conclusão</Text>
                        <Flex direction="row" align="center" >
                            <Flex w="50%" h="7px" bg="#F3F3F3" borderRadius="20">
                                <Flex w={`${categoryData.percentage}%`} h="7px" bg="primary" borderRadius="20" />
                            </Flex>
                            <Text ml="13px">
                                {`${categoryData.percentage} % `}
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Grid >
    )
}


export { CardStatisticCategory }