import { Flex, Text } from '@chakra-ui/react'
import { Grid } from '@material-ui/core'
import { format } from 'date-fns'

interface CardCategorieProps {
    courseId: number;
    onClick: (arg: number) => void;
    name: string;
    contentsTotal: number;
    percentageFinished: number;
    percentageStarted: number;
    contentsFinished: number;
    contentStarted: number;
    lastInteraction: string;
}

function CardCourse({ courseId, name, contentsTotal, contentStarted, percentageFinished, percentageStarted, contentsFinished, lastInteraction, onClick }: CardCategorieProps) {
    return (
        <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
            <Flex w="100%" h="180px" pt="2" pr="3" onClick={() => onClick(courseId)}>
                <Flex direction="column" cursor="pointer" w="100%" h="100%" p="3" bg="white" borderRadius="2px" shadow="0px 2px 4px #ABABAB80" borderWidth="1px" borderColor="#E2E2E2">
                    <Flex w="100%" >
                        <Text fontSize={["16px", "14px",]} >{name}</Text>
                    </Flex>
                    <Text mt="auto" fontSize="12px" color={lastInteraction ? "black" : "red.500"}> {lastInteraction ? `Último acesso em: ${format(new Date(lastInteraction), "dd/MM/yy HH:mm")}` : "Nunca Acessou"}</Text>
                    <Text mt="auto" fontWeight="semibold">Conclusão</Text>
                    <Flex direction="row" align="center" >
                        <Flex w="50%" h="7px" bg="#F3F3F3" borderRadius="20">
                            <Flex w={`${percentageFinished || 0}%`} h="7px" bg="green.500" borderRadius="20" />
                        </Flex>
                        <Text ml="13px">
                            {`${percentageFinished} %`}
                        </Text>
                        <Text color="gray.400" ml="auto">
                            {`${contentsFinished}/${contentsTotal}`}
                        </Text>
                    </Flex>
                    {
                        percentageFinished !== 100 &&
                        <Flex align="center">
                            <Flex w="50%" h="7px" bg="#F3F3F3" borderRadius="20">
                                <Flex w={`${percentageStarted || 0}%`} h="7px" bg="primary" borderRadius="20" />
                            </Flex>
                            <Text ml="13px">
                                {`${percentageStarted} %`}
                            </Text>
                            <Text color="gray.400" ml="auto">
                                {`${contentStarted}/${contentsTotal - contentsFinished}`}
                            </Text>
                        </Flex>
                    }
                </Flex>
            </Flex>
        </Grid>
    )
}

export { CardCourse }