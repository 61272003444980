export const ENV = process.env;

export const IS_DEV = ENV.NODE_ENV === 'development';

export const IS_PROD = ENV.NODE_ENV === 'production';

export const IS_TEST = ENV.NODE_ENV === 'test';


export const API_HOST_TEST = "http://localhost:3000/"
export const API_HOST = ENV.REACT_APP_API_HOST;