import { BrowserRouter as Router, Switch } from "react-router-dom";
import Route from "./Route";
import { Students } from "../pages/Students";
import { Student } from "../pages/Student";
import { Login } from "../pages/Login";
import { StudentStatistic } from "../pages/StudentStatistic";
import { RankStudents } from "../pages/RankStudents";
import { CursesPage } from "../pages/Courses";

function Routers() {
    return (
        <Router>
            <Switch>
                <Route path="/" exact component={Login} />
                <Route path="/students" component={Students} isPrivate exact />
                <Route
                    exact
                    path="/student/:studentId/:backRouter"
                    component={Student}
                    isPrivate
                />
                <Route
                    exact
                    path="/student-statistic/:studentParamId/categorie/:categorieParamId/course/:courseParamId"
                    component={StudentStatistic}
                    isPrivate
                />
                <Route
                    exact
                    path="/rankstudent"
                    component={RankStudents}
                    isPrivate
                />
                <Route exact path="/course" component={CursesPage} isPrivate />
                <Route component={Students} isPrivate />
            </Switch>
        </Router>
    );
}

export { Routers };
