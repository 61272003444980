import Drawer from '../../components/Drawer'
import { Students as StudentsPage } from './students'

export function RankStudents() {

    return (
        <Drawer screenId={1}>
            <StudentsPage />
        </Drawer>
    );
}
