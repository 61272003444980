import React, { useState } from "react";
import {
    Flex,
    Box,
    Text,
    Button,
    Stack,
    Image,
    CircularProgress,
} from "@chakra-ui/react";
import FullCycle from "../../assets/logo/fullcycle.svg";
import { useDispatch, useSelector } from "react-redux";
import { signInRequest } from "../../store/modules/auth/actions";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { StoreState } from "../../store/createStore";
import { Input as InputBase } from "../../components/InputBase";

const signInFormSchema = yup.object().shape({
    email: yup
        .string()
        .email("O email informado não é válido.")
        .required("Por favor, inserir o e-mail."),
    password: yup
        .string()
        .min(6, "Senha inválida.")
        .required("Por favor, inserir a senha."),
});

interface SignInFormData {
    email: string;
    password: string;
}

const Login: React.FC = () => {
    const [errorMessage, setErrorMessage] = useState("");

    const { register, handleSubmit, formState } = useForm({
        resolver: yupResolver(signInFormSchema),
    });

    const { errors } = formState;

    const { loading } = useSelector((state: StoreState) => state.auth);

    const dispatch = useDispatch();

    const handleSignIn: SubmitHandler<SignInFormData> = (values) => {
        setErrorMessage("");
        dispatch(
            signInRequest({
                email: values.email,
                password: values.password,
                setErrorMessage,
            })
        );
    };
    return (
        <Flex
            w="100vw"
            h="100vh"
            bg="black"
            justifyContent="center"
            alignItems="center"
        >
            {loading ? (
                <Flex direction="column">
                    <CircularProgress
                        isIndeterminate
                        color="primary"
                        size="120px"
                    />
                </Flex>
            ) : (
                <Flex
                    as="form"
                    onSubmit={handleSubmit(handleSignIn)}
                    maxW="500px"
                    width="100%"
                    bg="black"
                    direction="column"
                    p="5"
                    borderRadius="7"
                >
                    <Stack spacing="4">
                        <Image
                            htmlWidth={"200px"}
                            name="Ezequiel Lopes"
                            src={FullCycle}
                        />
                        <Box>
                            <InputBase
                                type="email"
                                label="Email"
                                data-testid="email-input"
                                error={errors.email}
                                {...register("email")}
                            />
                        </Box>
                        <Box>
                            <InputBase
                                type="password"
                                label="Senha"
                                data-testid="password-input"
                                error={errors.password}
                                {...register("password")}
                            />
                        </Box>
                        <Box>
                            <Text color="red.500">{errorMessage}</Text>
                        </Box>
                        <Box display="flex" justifyContent="flex-end">
                            <Button
                                type="submit"
                                data-testid="entrar-button"
                                isLoading={formState.isSubmitting}
                                bg="primary"
                                maxW="150px"
                                w="100%"
                            >
                                Entrar
                            </Button>
                        </Box>
                    </Stack>
                </Flex>
            )}
        </Flex>
    );
};

export { Login };
