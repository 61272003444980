import Cookies from "js-cookie";

export const getCookies = () => {
    const cookies = Cookies.get("authorization");
    return cookies;
};

export const setCookies = (token: string | null) => {
    if (token) {
        Cookies.set("authorization", token, { secure: true });
    }
};

export const removeCookies = () => {
    Cookies.remove("authorization");
};

