import { rest } from 'msw'
import { API_HOST, API_HOST_TEST, IS_TEST } from '../../../constants';
import { categorie } from './response'

import {
    Categories1001coursesProps,
    CategoriesOptionsProps,
    Categories13Props,
    Categories163courses,
    CategoriesCoursesChaptersContentsProps,
    CategoriesCoursesProps,
    CategoriesCoursesChaptersProps
} from './types'

const composeEndpoint = (endpoint: string) =>
    `${IS_TEST ? API_HOST_TEST : API_HOST}${endpoint}`;


export const categories = [
    rest.get<undefined, Categories1001coursesProps[]>(composeEndpoint('categories/1001/stats-courses'),
        (_req, res, ctx) => res(ctx.status(200), ctx.json(
            categorie['categories/1001/stats-courses']
        ))
    ),
    rest.get<undefined, Categories163courses[]>(composeEndpoint('categories/163/stats-courses'),
        (_req, res, ctx) => res(ctx.status(200), ctx.json(
            categorie['categories/163/stats-courses']
        ))
    ),
    rest.get<undefined, CategoriesOptionsProps[]>(composeEndpoint('categories/options'),
        (_req, res, ctx) => res(ctx.status(200), ctx.json(
            categorie['categories/options']
        ))
    ),
    rest.get<undefined, Categories13Props>(composeEndpoint('categories/13'),
        (_req, res, ctx) => res(ctx.status(200), ctx.json(
            categorie['categories/13']
        ))
    ),
    rest.get<undefined, []>(composeEndpoint('categories/163/courses/163/chapters/163/contents'),
        (_req, res, ctx) => res(ctx.status(200), ctx.json(
            []
        ))
    ),
    rest.get<undefined, CategoriesCoursesChaptersContentsProps[]>(composeEndpoint('categories/163/courses/102/chapters/676/contents'),
        (_req, res, ctx) => res(ctx.status(200), ctx.json(
            categorie['categories/163/courses/102/chapters/676/contents']
        ))
    ),
    rest.get<undefined, CategoriesCoursesProps[]>(composeEndpoint('categories/163/courses'),
        (_req, res, ctx) => res(ctx.status(200), ctx.json(
            categorie['categories/163/courses']
        ))
    ),
    rest.get<undefined, CategoriesCoursesChaptersProps[]>(composeEndpoint('categories/163/courses/102/chapters'),
        (_req, res, ctx) => res(ctx.status(200), ctx.json(
            categorie['categories/163/courses/102/chapters']
        ))
    ),
]