export function cleanObject(value: any) {
    const obj: any = Object.assign({}, value)

    for (let propName in value) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '0' || "") {
            delete obj[propName];
        }
    }

    return obj
}