import { CircularProgress, Table, TableContainer } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Drawer from "../../components/Drawer";
import { CoursesHttp } from "../../services/api/resource/course";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import {
    Box,
    makeStyles,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { toast } from "react-toastify";

type CursesAndStudentsProps = {
    _id: number;
    name: string;
    total: number;
};

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.primary.main,
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: "#d9d9d9",
            },
        },
    })
)(TableRow);

function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number
) {
    return { name, calories, fat, carbs, protein };
}
const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

export function CursesPage() {
    const classes = useStyles();

    const [cursesAndStudents, setCursesAndStudents] = useState<
        CursesAndStudentsProps[]
    >([]);
    const [loadingCourses, setLoadingCourses] = useState<boolean>(false);

    useEffect(() => {
        async function Home() {
            setLoadingCourses(true);
            try {
                const coursesHttp = new CoursesHttp();
                const dados = await coursesHttp.find();
                setCursesAndStudents(
                    dados.sort((prev, next) =>
                        prev.name.localeCompare(next.name)
                    )
                );
                setLoadingCourses(false);
            } catch (err) {
                toast.error("Falha no carregamento");
                setLoadingCourses(false);
            }
        }
        Home();
    }, []);

    return (
        <Drawer screenId={2}>
            {loadingCourses ? (
                <Box>
                    <CircularProgress isIndeterminate color="primary" />
                    Carregando
                </Box>
            ) : (
                <TableContainer component={Paper}>
                    <Table
                        className={classes.table}
                        aria-label="customized table"
                    >
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>ID</StyledTableCell>
                                <StyledTableCell>Curso</StyledTableCell>
                                <StyledTableCell>Alunos</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cursesAndStudents.map((row) => (
                                <StyledTableRow key={row.name}>
                                    <StyledTableCell component="th" scope="row">
                                        {row._id}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.name}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.total}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Drawer>
    );
}

