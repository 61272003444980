import { AxiosInstance } from "axios";
import { HttpResource } from "../../httpResource";
import { BadResponseProps, UserLoginProps, ResponseProps } from "./types";


export class UserLoginHttp extends HttpResource {
    email: string;
    password: string;
    constructor(public http: AxiosInstance, public data: UserLoginProps) {
        super(http, 'auth/login');
        this.email = data.email
        this.password = data.password
    }

    async login(): Promise<BadResponseProps | ResponseProps> {
        const userLogin = await super.post<BadResponseProps | ResponseProps>(this.data)
        return userLogin
    }
}