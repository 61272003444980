import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

import { Text, Box, Flex, Image, CircularProgress } from "@chakra-ui/react";
import Pagination from "@material-ui/lab/Pagination";

import { InputSelect } from "../../components/InputSelect";
import { api } from "../../services/api";

import { PerPage } from "../../components/PerPage";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import BookReadPrimary from "../../assets/logo/book-read-user-primary.svg";

import { GridRankStudents } from "../../components/GridRankStudents";
import { CategoryOptionHttp } from "../../services/api/resource";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { HttpResource } from "../../services/api/httpResource";
import { useStyles } from "./styles";
import queryString from "query-string";
import { cleanObject } from "../../function/cleanObject";
import { toast } from "react-toastify";

interface CategoriesProps {
    _id: number;
    name: string;
}

interface StudentsProps {
    _id: number;
    name: string;
    email: string;
    contents_started: number;
    contents_finished: number;
    percentage_finished: number;
    phone: string;
    cellphone: string;
    categories: {
        total_contents: number;
    };
    classroom_name: string;
    last_interaction: string;
}

export function Students() {
    const [categories, setCategories] = useState<CategoriesProps[]>([]);
    const [loadingCourses, setLoadingCourses] = useState<boolean>(false);

    const [categoryId, setCategoryId] = useState(
        localStorage.getItem("@codestudent/categoryId") || ""
    );

    const [students, setStudents] = useState<StudentsProps[]>([]);
    const [loadingStudents, setLoadingStudents] = useState<boolean>(false);

    const [order, setOrder] = useState("finished");

    const perPageArray = [
        { _id: 5, name: "5" },
        { _id: 10, name: "10" },
        { _id: 20, name: "20" },
        { _id: 30, name: "30" },
        { _id: 50, name: "50" },
        { _id: 100, name: "100" },
    ];
    const [perPage, setPerPage] = useState(10);

    const [page, setPage] = useState(1);
    const [meta, setMeta] = useState({ total: 0, lastPage: 0 });
    const [totalContents, setTotalContents] = useState(0);

    const theme = useTheme();

    const classes = useStyles();

    useEffect(() => {
        (async () => {
            setLoadingCourses(true);
            const categoryOption = await new CategoryOptionHttp(api).list();

            setCategories([...categoryOption]);
            setLoadingCourses(false);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            setLoadingStudents(true);
            setPage(1);
            const dadosRequest = cleanObject({
                category_id: categoryId || null,
                order: order || 0,
                page: 1,
                per_page: perPage || null,
            });
            const queryUrl = queryString.stringify(dadosRequest);
            if (categoryId) {
                try {
                    const response = await new HttpResource(
                        api,
                        `/students/category/${categoryId}/ranking?${queryUrl}`
                    ).list<{
                        data: StudentsProps[];
                        meta: { total: 0; lastPage: 0 };
                    }>();
                    if (response.data[0]) {
                        setTotalContents(
                            response.data[0].categories.total_contents
                        );
                    }
                    setStudents(response.data);
                    setMeta(response.meta);

                    setLoadingStudents(false);
                } catch {
                    toast.error("Falha no carregamento");
                    setLoadingStudents(false);
                }
            }
            setLoadingStudents(false);
        })();
    }, [categoryId, perPage, order]);

    useEffect(() => {
        (async () => {
            setLoadingStudents(true);
            if (categoryId) {
                const dadosRequest = cleanObject({
                    category_id: categoryId || null,
                    order: order || 0,
                    page: page || 1,
                    per_page: perPage || null,
                });
                const queryUrl = queryString.stringify(dadosRequest);
                try {
                    const response = await new HttpResource(
                        api,
                        `/students/category/${categoryId}/ranking?${queryUrl}`
                    ).list<{
                        data: StudentsProps[];
                        meta: { total: 0; lastPage: 0 };
                    }>();
                    if (response.data[0]) {
                        setTotalContents(
                            response.data[0].categories.total_contents
                        );
                    }

                    setStudents(response.data);
                    setMeta(response.meta);
                    setLoadingStudents(false);
                } catch {
                    setLoadingStudents(false);
                    toast.error("Falha no carregamento");
                }
            }
            setLoadingStudents(false);
        })();
    }, [page]);

    async function handleChangeCleanSearch() {
        setCategoryId("");
        setPerPage(10);
        setPage(1);
        setMeta({ total: 0, lastPage: 0 });

        const responseTurma = {
            data: [],
            meta: { total: 0, lastPage: 0 },
        };

        setStudents(responseTurma.data);
        setMeta(responseTurma.meta);
    }

    const handleChangePage = (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setPage(value);
    };

    const matches = useMediaQuery(theme.breakpoints.up("md"));
    const matchesDownMd = useMediaQuery(theme.breakpoints.down("md")) || false;
    const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"));

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOrder((event.target as HTMLInputElement).value);
    };

    function handleCourseId(valueId: string) {
        setCategoryId(valueId);
        localStorage.setItem("@codestudent/categoryId", valueId);

        setPage(1);
    }

    return (
        <>
            <Box>
                <Box>
                    <Flex align="center" mb="10px">
                        <Image src={BookReadPrimary} ml="2" mr="2" />
                        <Text ml="2" fontSize="22px" fontWeight="semibold">
                            Rank dos Alunos
                        </Text>
                    </Flex>
                </Box>
                <Box ml={matchesDownMd ? "10px" : "20px"}>
                    <Box mb="50px">
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                {loadingCourses ? (
                                    <Box>
                                        <CircularProgress
                                            isIndeterminate
                                            color="primary"
                                        />
                                        Carregando Cursos
                                    </Box>
                                ) : (
                                    <InputSelect
                                        title="Cursos"
                                        array={categories}
                                        value={categoryId}
                                        onChange={handleCourseId}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                <FormControl
                                    component="fieldset"
                                    style={{ marginTop: "-20px" }}
                                >
                                    <FormLabel component="legend">
                                        Ordenação
                                    </FormLabel>
                                    <RadioGroup
                                        aria-label="gender"
                                        name="gender1"
                                        value={order}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel
                                            value="finished"
                                            control={
                                                <Radio
                                                    classes={{
                                                        root: classes.radio,
                                                        checked:
                                                            classes.checked,
                                                    }}
                                                />
                                            }
                                            label="Finalizados"
                                        />
                                        <FormControlLabel
                                            value="started"
                                            control={
                                                <Radio
                                                    classes={{
                                                        root: classes.radio,
                                                        checked:
                                                            classes.checked,
                                                    }}
                                                />
                                            }
                                            label="Iniciados"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                    <Flex mb="50px" direction="column">
                        <Flex align="center">
                            {loadingStudents ? (
                                <Box>
                                    <CircularProgress
                                        isIndeterminate
                                        color="primary"
                                    />
                                    Carregando Alunos
                                </Box>
                            ) : (
                                <>
                                    <Text
                                        fontSize="22px"
                                        fontWeight="semibold"
                                    >{`${meta.total} Alunos`}</Text>
                                    <Text ml="4" mr="4" fontSize="14px">
                                        {` - `}{" "}
                                    </Text>
                                    <Text
                                        fontSize="14px"
                                        cursor="pointer"
                                        color="subText"
                                        onClick={handleChangeCleanSearch}
                                    >
                                        {`Limpar Filtro`}{" "}
                                    </Text>
                                </>
                            )}
                        </Flex>
                        {meta.total === 0 && (
                            <Text mt="8">
                                Não foram encontrados alunos para este filtro.
                            </Text>
                        )}
                    </Flex>

                    {categoryId && !loadingStudents ? (
                        students.map((item) => (
                            <GridRankStudents
                                backRouter="students"
                                matchesDownMd={matchesDownMd}
                                matchesDownSm={matchesDownSm}
                                key={item._id}
                                _id={item._id}
                                phone={item.phone}
                                cellphone={item.cellphone}
                                name={item.name}
                                email={item.email}
                                totalContents={totalContents}
                                contents_started={item.contents_started}
                                contents_finished={item.contents_finished}
                                percentage_finished={item.percentage_finished}
                                categories={item.classroom_name}
                                last_interaction={item.last_interaction}
                            />
                        ))
                    ) : loadingStudents ? (
                        <>
                            <CircularProgress isIndeterminate color="primary" />
                        </>
                    ) : (
                        categories.length > 0 && (
                            <Text fontSize="22px">
                                Você deve selecionar uma categoria para que os
                                alunos sejam exibidos.
                            </Text>
                        )
                    )}
                    <Flex mt="4" justifyContent="center">
                        <Flex
                            flexDirection={matches ? "row" : "column"}
                            align="center"
                            ml={matches ? "auto" : "0px"}
                        >
                            <PerPage
                                perPageArray={perPageArray}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            />
                            <Box
                                mt={matches ? "0px" : "15px"}
                                mb={matches ? "0px" : "5px"}
                            >
                                <Pagination
                                    count={meta.lastPage}
                                    page={page}
                                    onChange={handleChangePage}
                                    color="primary"
                                />
                            </Box>
                        </Flex>
                    </Flex>
                </Box>
            </Box>
        </>
    );
}
