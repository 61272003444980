import { AnyAction, Middleware, Store } from 'redux';
import { persistStore } from 'redux-persist';
//import persistStore from 'redux-persist/lib/persistStore';
import createSagaMiddleware from 'redux-saga'

import createStore from './createStore';
import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';
import persistReducer from './persistReducer';

const sagaMiddleware = createSagaMiddleware();


const middleware: Middleware[] = [sagaMiddleware];

const store = createStore(persistReducer(rootReducer), middleware);
const persistor = persistStore(store as unknown as Store<any, AnyAction>);

sagaMiddleware.run(rootSaga);


export { store, persistor };
