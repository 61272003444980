import axios, { AxiosError } from "axios";
import { IS_TEST, API_HOST, API_HOST_TEST } from "../../constants";
import { getCookies, setCookies, removeCookies } from "../../function/cookies";
import Cookies from "js-cookie";
import { store } from "../../store";
import { signOut } from "../../store/modules/auth/actions";

const api = axios.create({
    baseURL: IS_TEST ? API_HOST_TEST : API_HOST,
});

export const getRefreshToken = async () => {
    try {
        const refreshToken = await api.post("/auth/refresh-token");
        if (refreshToken?.data?.token) {
            setCookies(refreshToken.data.token);
        }
    } catch (error) {
        removeCookies();
        const { dispatch } = store;
        dispatch(signOut());
    }
};

api.interceptors.request.use(
    (config) => {
        const cookies = Cookies.get("authorization");
        if (!!cookies) {
            config.headers = {
                Authorization: `Bearer ${cookies}`,
                "Content-Type": "application/json",
            };
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
        const cookies = Cookies.get("authorization");
        if (error?.response?.status === 401) {
            if (
                error.config.url !== "/auth/refresh" &&
                error.config.url !== "auth/login" &&
                !!cookies
            ) {
                await getRefreshToken();
                const newCookies = getCookies();
                error.config.headers = {
                    Authorization: `Bearer ${newCookies}`,
                    "Content-Type": "application/json",
                };
                return Promise.resolve(api.request(error.config));
            } else {
                removeCookies();
                const { dispatch } = store;
                dispatch(signOut());
            }
        }
        return Promise.reject(error);
    }
);

export { api };
