import { IoIosPodium, IoIosList } from "react-icons/io";
import BookRead from "../../assets/logo/book-read-user.svg";

export const menu = [
    {
        title: "Alunos",
        icon: BookRead,
        isImage: true,
        route: "students",
    },
    {
        title: "Ranking",
        icon: IoIosPodium,
        route: "rankstudent",
    },
];
