import React from 'react';
import { Flex, Text } from '@chakra-ui/react'
import CardSemaphore from '../CardSemaphore';
import { FiEye } from 'react-icons/fi'
import { format } from 'date-fns'

interface CardConteudosProps {
    contentId: number;
    title: string;
    dateStarted: string | null;
    dateFinished: string | null;
    amountOfViewing: number;
    contentState: number;
}
const CardContent: React.FC<CardConteudosProps> = (props) => {
    const {
        contentId,
        title,
        dateStarted,
        dateFinished,
        amountOfViewing,
        contentState
    } = props;

    const contentOrdenId = `${contentId}`
    const heightByResolution = ["40px", "50px", "60px", "60px", "70px"]

    const formatContentId = `${contentOrdenId.length === 1 ? "0" : ""}${contentOrdenId}`

    return (
        <Flex mt="20px" w="100%" h={heightByResolution} borderRadius="5px" boxShadow="0px 0px 10px 0px rgba(0,0,0,0.4)">
            <Flex h={heightByResolution} w={"100px"} justifyContent="center" alignItems="center">
                <Text fontSize="16px">{formatContentId}</Text>
            </Flex>
            <Flex alignItems="center" w="100%">
                <Text fontSize="16px">{title}</Text>
                <Flex direction="row" ml="auto" alignItems="center" >
                    <Text ml="auto" data-testid="dateStarted">{dateStarted && format(new Date(dateStarted), 'dd/MM/yy - HH:mm')}</Text>
                    <Text ml="5px" mr="5px" fontSize="22px" data-testid="dateFinished•" >{dateFinished && "•"}</Text>
                    <Text ml="auto" data-testid="dateFinished">{dateFinished && format(new Date(dateFinished), 'dd/MM/yy - HH:mm')}</Text>
                </Flex>
                <Flex ml="20px">
                    <FiEye size="20px" />
                    <Text ml="7px" mr="20px" data-testid="amountOfViewing" >{amountOfViewing}</Text>
                </Flex>
            </Flex>
            <CardSemaphore type={contentState} />
        </Flex>
    )
}

export { CardContent };

