import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { IS_TEST } from './constants'
import './global/global.css'

if (IS_TEST) {
    const { worker } = require('./__mocks__')
    worker.start()
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

