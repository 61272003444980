import { useEffect, useState } from "react";
import { Box, CircularProgress, Flex, Text } from "@chakra-ui/react";
import Drawer from "../../components/Drawer";
import { HeaderBar } from "../../components/HeaderBar";
import { CardStatisticCategory } from "../../components/CardStatisticCategory";
import { CardCourse } from "../../components/CardCourse";
import { Grid } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../../services/api";
import {
    StudentStatsCourseHttp,
    StudentCourseHttp,
} from "../../services/api/resource";
import {
    StudentCoursesProps,
    RouterParamsProps,
    UserDataProps,
    StudentInformationProps,
    CategoriesProps,
} from "./types";

function Student() {
    const { backRouter, ...paramsRouter } = useParams<RouterParamsProps>();

    const history = useHistory();

    const studentId = Number(paramsRouter.studentId);

    const [categoryDetails, setCategoryDetails] = useState({
        id: 163,
        name: "",
    });
    const [loadingCategories, setLoadingCategories] = useState(false);
    const [categories, setCategories] = useState<CategoriesProps[]>([]);

    const [loadStdData, setLoadStdData] = useState(false);
    const [studentData, setStudentData] = useState<UserDataProps>({
        _id: 0,
        name: "",
        email: "",
        active: true,
        phone: "",
        cellphone: "",
        last_interaction: null,
    });
    const [loadingCourses, setLoadingCourses] = useState(false);
    const [courses, setCourses] = useState<StudentCoursesProps[]>([]);

    useEffect(() => {
        setLoadingCourses(true);
        (async () => {
            const studentStatsCourse = await new StudentStatsCourseHttp(
                api,
                studentId,
                categoryDetails.id
            ).sortPercentage();
            setCourses(studentStatsCourse);
            setLoadingCourses(false);
        })();
    }, [categoryDetails]);

    useEffect(() => {
        setLoadStdData(true);
        setLoadingCategories(true);
        (async () => {
            const studentInformation = await new StudentCourseHttp(
                api,
                studentId
            ).list<StudentInformationProps>();
            const studentStatsCourse = await new StudentStatsCourseHttp(
                api,
                studentId,
                studentInformation.categories[0]._id
            ).sortPercentage();
            const { _id: courseId, name: courseName } =
                studentInformation.categories[0];

            setStudentData({
                _id: studentInformation._id,
                name: studentInformation.name,
                email: studentInformation.email,
                active: studentInformation.active,
                last_interaction: studentInformation.last_interaction,
                cellphone: studentInformation.cellphone,
                phone: studentInformation.phone,
            });
            setLoadStdData(false);
            setCategories(studentInformation.categories);
            setCategoryDetails({ id: courseId, name: courseName });
            setLoadingCategories(false);

            setCourses(studentStatsCourse);
        })();
    }, []);

    function handleRedirectRouterStatic(courseId: number) {
        history.push(
            `/student-statistic/${studentId}/categorie/${categoryDetails.id}/course/${courseId}`
        );
    }

    function BsArrowLeftFunction() {
        if (backRouter === "rank") {
            history.push(`/rankstudent`);
            return;
        }
        history.push(`/students`);
    }
    return (
        <Drawer screenId={0}>
            <Flex direction="column">
                {loadStdData ? (
                    <Box>
                        <CircularProgress isIndeterminate color="primary" />
                        Carregando Dados
                    </Box>
                ) : (
                    <HeaderBar
                        BsArrowLeftFunction={() => BsArrowLeftFunction()}
                        active={studentData.active}
                        name={studentData.name}
                        email={studentData.email}
                        lastAccess={studentData.last_interaction}
                    />
                )}
            </Flex>
            {true && (
                <Flex mt="50px" direction="column">
                    <Text fontSize="22px" fontWeight="semibold">
                        Cursos
                    </Text>
                    <Flex maxW="100%">
                        <Grid container>
                            {loadingCategories ? (
                                <Box>
                                    <CircularProgress
                                        isIndeterminate
                                        color="primary"
                                    />
                                    Carregando Categorias
                                </Box>
                            ) : (
                                categories.map((item, index) => (
                                    <CardStatisticCategory
                                        key={index}
                                        categoryId={item._id}
                                        studentId={studentId}
                                        categoriesName={item.name}
                                        setCourseDetails={setCategoryDetails}
                                    />
                                ))
                            )}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Flex mt="20px" h="1px" w="100%" bg="border" />
                            </Grid>
                        </Grid>
                    </Flex>
                </Flex>
            )}
            <Flex
                mt="50px"
                direction="column"
                bg="backgroundCard"
                p="2"
                borderRadius="2px"
            >
                <Flex mb="30px" mt="20px" align="center">
                    <Text fontSize="22px" fontWeight="semibold">
                        Curso
                    </Text>
                    <Text ml="5" fontSize="18px">
                        {loadingCategories ? (
                            <Box>
                                <CircularProgress
                                    isIndeterminate
                                    color="primary"
                                />
                                Carregando Categorias
                            </Box>
                        ) : (
                            categoryDetails.name
                        )}
                    </Text>
                </Flex>
                <Flex maxW="100%">
                    <Grid container>
                        {loadingCourses ? (
                            <Box>
                                <CircularProgress
                                    isIndeterminate
                                    color="primary"
                                />
                                Carregando Cursos
                            </Box>
                        ) : (
                            courses.map((item, index) => (
                                <CardCourse
                                    courseId={item._id}
                                    onClick={handleRedirectRouterStatic}
                                    key={index}
                                    name={item.name}
                                    lastInteraction={item.lastInteraction}
                                    contentsFinished={item.contentsFinished}
                                    contentStarted={item.contentsStarted}
                                    contentsTotal={item.contentsTotal}
                                    percentageFinished={item.percentageFinished}
                                    percentageStarted={item.percentageStarted}
                                />
                            ))
                        )}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Flex mt="20px" h="1px" w="100%" bg="border" />
                        </Grid>
                    </Grid>
                </Flex>
            </Flex>
        </Drawer>
    );
}

export { Student };
