import { AxiosInstance } from "axios";

export class HttpResource {
    constructor(public http: AxiosInstance, public resource: string) { }
    list<T = any>(): Promise<T> {
        return this.http.get(this.resource).then((res) => res.data);
    }
    get<T = any>(url: string): Promise<T> {
        return this.http.get(url).then((res) => res.data);
    }
    post<T = any>(data: any): Promise<T> {
        return this.http.post(this.resource, data).then((res) => res.data);
    }
}