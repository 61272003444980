import { createStyles, makeStyles, Theme } from '@material-ui/core'


export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        button: {
            display: 'block',

        },
        formControl: {
            minWidth: 120,
        },
        iconColor: {
            background: theme.palette.background.paper,
        },
        radio: {
            '&$checked': {
                color: theme.palette.primary.main,
            }
        },
        checked: {},
        ul: {
            "& .MuiPaginationItem-page:hover": {
                color: "rgba(170,170,170,0.8)",
                background: "rgba(170,170,170,0.8)",
                ".MuiPaginationItem-root": {
                    color: "#6d6"
                }
            },
            "&.MuiPaginationItem-page:hover &.MuiPaginationItem-root": {
                color: "#16f"
            },
            ".Mui-selected": {
                color: "#98f",
                background: "#98f"
            },
            "& .Mui-selected": {
                color: "#9ff",
                background: "#9ff"
            },
            "& .Mui-focusVisible": {
                color: "#56f",
                background: "#f6f",
            },
            "& .MuiPaginationItem-root": {
                color: "#000"
            }
        }
    })
});