import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';


const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        image: {
            cursor: "pointer"
        },
        iconColorAppBar: {
            cursor: 'pointer',
            color: theme.palette.background.paper,
        },
        iconColorDrawer: {
            color: theme.palette.common.black,
        },
        list: {
            width: 250,
        },
        iconColor: {
            color: "#fff"
        },
        iconColorSm: {
            color: "#333"
        },
        appBarMobile: {
            background: theme.palette.common.black,
        },
        appBar: {
            background: theme.palette.common.black,
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            background: theme.palette.common.black,
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 36,
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            borderRight: 'none',
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: drawerWidth,
            boxShadow: '3px 2px 2px 2px rgba(0, 0, 0, 0.1)',
            height: '100%',
            borderRight: 'none',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            borderRight: 'none',
            boxShadow: '3px 2px 2px 2px rgba(0, 0, 0, 0.1)',
            height: '100%',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        rootAppBar: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: 400,
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
            "& ::placeholder": {
                color: "#999999",
                opacity: 1,
            },
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            height: 28,
            margin: 4,
        },
        colorIcon: {
            color: "#B8B8B8"
        }
    }),
);

export default useStyles